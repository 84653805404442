import Cookies from 'js-cookie';

const TokenKey = 'xucxich_admin_token';
const UserKey = 'xucxich_admin_user';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
    document.cookie =`xucxich_admin_token=${token};SameSite=Lax`;
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getUser() {
    var user =  Cookies.get(UserKey)
    if (user !== null && typeof user !== "undefined") {
      return JSON.parse(user);
    }
    return {}
  }
  
  export function setUser(user) {
      document.cookie =`xucxich_admin_user=${JSON.stringify(user)}`;
    // return Cookies.set(UserKey, JSON.stringify(user));
  }
  
  export function removeUser() {
    return Cookies.remove(UserKey);
  }