<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-form-item label="Feature Image" style="max-width: 600px">
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                    :auto-upload="false"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" label="Categories" style="max-width: 600px;">
                <el-select
                    v-model="form.category_ids"
                    multiple
                    placeholder="Select"
                    style="width: 240px"
                    filterable
                    >
                    <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="User" style="max-width: 600px;">
                <el-select v-model="form.user_id" filterable>
                <el-option
                    v-for="(item, index) in users"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Tags" style="max-width: 600px;">
                <el-select
                    v-model="form.tags"
                    multiple
                    placeholder="Tags"
                    style="width: 240px"
                    allow-create="true"
                    filterable="true"
                    >
                    <el-option
                        v-for="item in tags"
                        :key="item"
                        :label="item"
                        :value="item"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Name" style="max-width: 600px;">
                <el-input v-model="form.name" placeholder="name" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Keywords" style="max-width: 600px;">
                <el-input v-model="form.keywords" placeholder="Keywords" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Meta Description" style="max-width: 600px;">
                <el-input type="textarea" v-model="form.meta_description" placeholder="" width="300px"  />
            </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'MakeArticleIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>
import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { list as listUsers } from '@/api/user';
import {list as listCategory} from '@/api/news_category';
import { add } from '@/api/make_article';
import { ElMessage } from 'element-plus';
export default {
    name: "MakeArticleAdd",
    data(){
        return {
            dialogVisible: false,
            form: {
                name: '',
                feature_image: '',
                keywords: '',
                meta_description: '',
                image: null,
                category_ids: [],
                tags: [],
            },
            users: [],
            categories: [],
            tags: [],
            image_previews: [],
        };
    },
    created(){
        this.getUsers();
        this.getCategory();
    },
    methods: {
        async getUsers(){
            let res = await listUsers('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.users = res.data.data;
            }
        },
        async getCategory(){
            let res = await listCategory('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input name',
                    type: 'error',
                });
                return;
            }
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: 'Please input keywords',
                    type: 'error',
                });
                return;
            }
            if (this.form.meta_description.trim() == '') {
                ElMessage({
                    message: 'Please input meta description',
                    type: 'error',
                });
                return;
            }
            if (this.form.category_ids.length <= 0) {
                ElMessage({
                    message: 'Please input meta categories',
                    type: 'error',
                });
                return;
            }
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            formData.append('user_id', this.form.user_id);
            for(let i = 0; i < this.form.tags.length; i++){
                formData.append('tags[]', this.form.tags[i]);
            }
            for(let j = 0; j < this.form.category_ids.length; j++) {
                formData.append('category_ids[]', Number(this.form.category_ids[j]));
            }
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            formData.append('status', 0);
            this.$store.state.loading = true;
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form.image = null;
            this.image_previews = [];
        },
        changeFile(e){
            this.form.image = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>