<template>
    <el-container style="margin: 30px 20px;">
    <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-form-item class="el-col" :span="24" label="Email" style="max-width: 600px" >
                <el-input v-model="form.email" placeholder="example@gmail.com" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Fullname" style="max-width: 600px">
                <el-input v-model="form.name" placeholder="name"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Password" style="max-width: 600px">
                <el-input v-model="form.password" placeholder=""  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Status" style="max-width: 600px">
                <el-select v-model="form.status">
                <el-option
                    v-for="item in statusList"
                    :key="item.val"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Role" style="max-width: 600px">
                <el-select v-model="form.role_ids" multiple>
                <el-option
                    v-for="item in roles"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
            </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'AdminIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
    </el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh
} from '@element-plus/icons-vue'
</script>
<script>
import { add } from '@/api/admin';
import { list as listRole} from '@/api/admin_role';
import { ElMessage } from 'element-plus';
export default {
    name: "AdminAdd",
    data(){
        return {
            form: {
                name: '',
                email: '',
                password: '',
                role_ids: 0,
                status: 0
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            roles: []
        };
    },
    created(){
        this.getRoles();
    },
    methods: {
        async getRoles(){
            this.$store.state.loading = true;
            let res = await listRole('page=1&pagesize=1000');
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.roles = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.email.trim() == '') {
                ElMessage({
                    message: 'Please input email',
                    type: 'error',
                });
                return;
            }
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input name',
                    type: 'error',
                });
                return;
            }
            if (this.form.password.trim() == '') {
                ElMessage({
                    message: 'Please input password',
                    type: 'error',
                });
                return;
            }
            if (this.form.role_ids.length <= 0) {
                ElMessage({
                    message: 'Please input roles',
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('email', this.form.email);
            formData.append('password', this.form.password);
            for (let i = 0; i < this.form.role_ids.length; i++) {
                formData.append('role_ids[]', this.form.role_ids[i]);
            }
            formData.append('status', this.form.status);
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                name: '',
                email: '',
                password: '',
                role_ids: 0,
                status: 0
            };
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>