<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-form-item class="el-col" :span="24" label="Feature Image" style="max-width: 600px">
            </el-form-item>
        </el-row>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                    auto-upload="false"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-form-item class="el-col" :span="24" label="Banner" style="max-width: 600px">
            </el-form-item>
        </el-row>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeBanner"
                    :before-upload="beforeBanner"
                    auto-upload="false"
                >

                <img v-if="form.banner_image" :src="form.banner_image" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" label="Name" style="max-width: 600px">
                <el-input v-model="form.name" placeholder="name" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Slug" style="max-width: 600px">
                <el-input v-model="form.slug" placeholder="slug" width="300px" readonly />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Owner" style="max-width: 600px">
                <el-input v-model="form.owner.email" placeholder="slug" width="300px" readonly />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Keywords" style="max-width: 600px">
                <el-input v-model="form.keywords" placeholder="Keywords" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Meta Description" style="max-width: 600px">
                <el-input type="textarea" v-model="form.meta_description" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item label="Description" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-notes"  v-model="form.description" :config="editorConfig"></ckeditor>
            <el-form-item class="el-col" :span="24" label="Address" style="max-width: 600px">
                <el-input v-model="form.address" placeholder="address" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Phone" style="max-width: 600px">
                <el-input v-model="form.phone" placeholder="phone" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Latitude" style="max-width: 600px">
                <el-input-number v-model="form.latitude" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Longitude" style="max-width: 600px">
                <el-input-number v-model="form.longitude" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Website" style="max-width: 600px">
                <el-input v-model="form.website" placeholder="http://example.com" width="300px"  />
            </el-form-item>
        <el-divider content-position="left">Social Pages</el-divider>
            <el-form-item class="el-col" :span="24" label="Facebook" style="max-width: 600px">
                <el-input v-model="form.facebook" placeholder="https://facebook.com" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Twitter" style="max-width: 600px">
                <el-input v-model="form.twitter" placeholder="https://twitter.com" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Instagram" style="max-width: 600px">
                <el-input v-model="form.instagram" placeholder="https://instagram.com" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Tiktok" style="max-width: 600px">
                <el-input v-model="form.tiktok" placeholder="https://tiktok.com" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Zalo" style="max-width: 600px">
                <el-input v-model="form.zalo" placeholder="zalo" width="300px"  />
            </el-form-item>
        <el-divider content-position="left">Payments</el-divider>
            <el-form-item class="el-col" :span="24" label="Bank Name" style="max-width: 600px">
                <el-input v-model="form.bank_name" placeholder="Bank name" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Bank Account" style="max-width: 600px">
                <el-input v-model="form.bank_account" placeholder="Nguyen Van A" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Bank Number" style="max-width: 600px">
                <el-input v-model="form.bank_number" placeholder="0123456" width="300px"  />
            </el-form-item>
            <el-form-item label="Payment Qrcode" style="max-width: 600px">
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFileQrcode"
                    :before-upload="beforeUploadQrcode"
                    :auto-upload="false"
                >

                <img v-if="form.payment_qrcode" :src="form.payment_qrcode" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" label="Template" style="max-width: 600px">
                <el-select v-model="form.template_id" >
                <el-option
                    v-for="(item, index) in templates"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" label="Category" style="max-width: 600px">
                <el-select v-model="form.shop_category_id" filterable>
                <el-option
                    v-for="(item, index) in categories"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" label="Province" style="max-width: 600px">
                <el-select v-model="form.province_code" >
                <el-option
                    v-for="(item, index) in provinces"
                    :key="index"
                    :label="item.name"
                    :value="item.code"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Status" style="max-width: 600px">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Is Top" style="max-width: 600px">
                <el-select v-model="form.is_top" >
                <el-option
                    v-for="(item, index) in topList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" label="Ship Shop ID" style="max-width: 600px">
                <el-select v-model="form.ghn_shop_id" filterable>
                <el-option
                    v-for="(item, index) in ship_shops"
                    :key="index"
                    :label="item.name + ' (' + item.phone +')' "
                    :value="item._id"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" label="Ship Province" style="max-width: 600px">
                <el-select v-model="form.ghn_province" @change="changeShipProvince" filterable>
                <el-option
                    v-for="(item, index) in ship_provinces"
                    :key="index"
                    :label="item.ProvinceName"
                    :value="item.ProvinceID"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" label="Ship District" style="max-width: 600px">
                <el-select v-model="form.ghn_district" @change="changeShipDistrict" filterable>
                <el-option
                    v-for="(item, index) in ship_districts"
                    :key="index"
                    :label="item.DistrictName"
                    :value="item.DistrictID"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" label="Ship Ward" style="max-width: 600px">
                <el-select v-model="form.ghn_ward_code" filterable>
                <el-option
                    v-for="(item, index) in ship_wards"
                    :key="index"
                    :label="item.WardName"
                    :value="item.WardCode"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'ShopIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { edit, detail } from '@/api/shop';
import {list as listShopTemplate} from '@/api/shop_template';
import {list as listCategory} from '@/api/shop_category';
import {list as listProvince} from '@/api/province';
import {provinces as shipProvinces, districts as shipDistricts, wards as shipWards, shops as shipShops} from '@/api/ship';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "ShopEdit",
    data(){
        return {
            form: {
                name: '',
                feature_image: '',
                slug: '',
                description: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                owner: {},
                payment_qrcode: '',
                address: '',
                phone: '',
                website: '',
                latitude: 0.0,
                longitude: 0.0,
                facebook: '',
                tiktok: '',
                twitter: '',
                instagram: '',
                zalo: '',
                bank_name: '',
                bank_account: '',
                bank_number: '',
                qrcode_file: null,
                template_id: 0,
                banner_image: '',
                banner_file: null,
                is_top: 0,
                shop_category_id: 0,
                province_code: null,
                ghn_district: '',
                ghn_province: '',
                ghn_shop_id: '',
                ghn_ward_code: ''
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            topList: [
                {
                    val: 0,
                    label: 'None Top'
                },
                {
                    val: 1,
                    label: 'Is Top'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            templates: [],
            categories: [],
            provinces: [],
            ship_provinces: [],
            ship_districts: [],
            ship_wards: [],
            ship_shops: [],
        };
    },
    created(){
        this.getTemplateShops();
        this.getCategory();
        this.getProvinces();
        this.getShipProvinces();
        this.getDetail();
        this.getShipShops();
    },
    methods: {
        async getTemplateShops(){
            let res = await listShopTemplate('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.templates = res.data.data;
            }
        },
        async getShipProvinces(){
            let res = await shipProvinces();
            if (res != null && res.status == 200 && res.data != null) {
                this.ship_provinces = res.data;
            }
        },
        async getShipDistricts(provinceId){
            let res = await shipDistricts(provinceId);
            if (res != null && res.status == 200 && res.data != null) {
                this.ship_districts = res.data;
            }
        },
        async getShipWards(districtId){
            this.$store.state.loading = true;
            let res = await shipWards(districtId);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.ship_wards = res.data;
            }
        },
        async getCategory(){
            let res = await listCategory('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async getShipShops(){
            this.$store.state.loading = true;
            let res = await shipShops('page=1&pagesize=100');
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.ship_shops = res.data.shops;
                console.log(this.ship_shops);
            }
        },
        async getProvinces(){
            let res = await listProvince('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.provinces = res.data.data;
            }
        },
        async getDetail(){
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                this.form.status = this.form.status ? 1 : 0;
                this.form.is_top = this.form.is_top ? 1 : 0;
                this.form.feature_image = this.form.feature_image != null && this.form.feature_image != '' ? process.env.VUE_APP_BASE_HOST + this.form.feature_image : null;
                this.form.banner_image = this.form.banner_image != null && this.form.banner_image != '' ? process.env.VUE_APP_BASE_HOST + this.form.banner_image : null;
                this.form.payment_qrcode = this.form.payment_qrcode != null && this.form.payment_qrcode != '' ? process.env.VUE_APP_BASE_HOST + this.form.payment_qrcode : null;
                this.form.latitude = Number(this.form.latitude);
                this.form.longitude = Number(this.form.longitude);
                this.form.ghn_province = this.form.ghn_province != null ?  Number(this.form.ghn_province) : '';
                if (this.form.ghn_province != null && this.form.ghn_province != '') {
                    this.getShipDistricts(this.form.ghn_province);
                    this.form.ghn_district = this.form.ghn_district != null ?  Number(this.form.ghn_district) : '';
                }
                if (this.form.ghn_district != null && this.form.ghn_district != '') {
                    this.getShipWards(this.form.ghn_district);
                }
                this.form.ghn_shop_id = this.form.ghn_shop_id != null ?  Number(this.form.ghn_shop_id) : '';
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input name',
                    type: 'error',
                });
                return;
            }
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: 'Please input keywords',
                    type: 'error',
                });
                return;
            }
            if (this.form.description.trim() == '') {
                ElMessage({
                    message: 'Please input description',
                    type: 'error',
                });
                return;
            }
            if (this.form.meta_description.trim() == '') {
                ElMessage({
                    message: 'Please input meta description',
                    type: 'error',
                });
                return;
            }
            if (this.form.ghn_district <= 0) {
                ElMessage({
                    message: 'Please input ship district',
                    type: 'error',
                });
                return;
            }
            if (this.form.ghn_ward_code == '') {
                ElMessage({
                    message: 'Please input ship ward',
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            formData.append('status', this.form.status);
            formData.append('is_top', this.form.is_top);
            formData.append('address', this.form.address);
            formData.append('phone', this.form.phone);
            formData.append('website', this.form.website);
            formData.append('facebook', this.form.facebook);
            formData.append('tiktok', this.form.tiktok);
            formData.append('twitter', this.form.twitter);
            formData.append('instagram', this.form.instagram);
            formData.append('zalo', this.form.zalo);
            formData.append('latitude', this.form.latitude.toString());
            formData.append('longitude', this.form.longitude.toString());
            formData.append('bank_name', this.form.bank_name);
            formData.append('bank_account', this.form.bank_account);
            formData.append('bank_number', this.form.bank_number);
            formData.append('ghn_district', this.form.ghn_district);
            formData.append('ghn_province', this.form.ghn_province);
            formData.append('ghn_shop_id', this.form.ghn_shop_id);
            formData.append('ghn_ward_code', this.form.ghn_ward_code);
            if (this.form.qrcode_file != null) {
                formData.append('payment_qrcode', this.form.qrcode_file);
            }
            if (this.form.banner_file != null) {
                formData.append('banner_file', this.form.banner_file);
            }
            if (this.form.template_id > 0) {
                formData.append('template_id', this.form.template_id);
            }
            if (this.form.province_code != null) {
                formData.append('province_code', this.form.province_code);
            }
            if (this.form.shop_category_id > 0) {
                formData.append('shop_category_id', this.form.shop_category_id);
            }
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.form.image = null;
        },
        changeFile(e){
            this.form.image = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        changeFileQrcode(e){
            this.form.qrcode_file = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.payment_qrcode = tmppath;
        },
        beforeUploadQrcode(rawFile){
            console.log(rawFile);
            return false;
        },
        changeBanner(e){
            this.form.banner_file = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.banner_image = tmppath;
        },
        beforeBanner(rawFile){
            console.log(rawFile);
            return false;
        },
        changeShipProvince(value){
            this.getShipDistricts(value);
        },
        changeShipDistrict(value){
            this.getShipWards(value);
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>