<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-form-item label="Feature Image" style="max-width: 600px">
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" label="Categories" style="max-width: 600px;">
                <el-select
                    v-model="form.news_category_ids"
                    multiple
                    placeholder="Select"
                    style="width: 240px"
                    filterable
                    >
                    <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Tags" style="max-width: 600px;">
                <el-select
                    v-model="form.tags"
                    multiple
                    placeholder="Tags"
                    style="width: 240px"
                    allow-create="true"
                    filterable="true"
                    >
                    <el-option
                        v-for="item in tags"
                        :key="item"
                        :label="item"
                        :value="item"
                    />
                    </el-select>
                </el-form-item>
            <el-form-item class="el-col" :span="24" label="Name" style="max-width: 600px;">
                <el-input v-model="form.name" placeholder="name" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Keywords" style="max-width: 600px;">
                <el-input v-model="form.keywords" placeholder="Keywords" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Meta Description" style="max-width: 600px;">
                <el-input type="textarea" v-model="form.meta_description" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item label="Description" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-notes"  v-model="form.description" :config="editorConfig"></ckeditor>
            <el-form-item class="el-col" :span="24" label="Status" style="max-width: 600px;">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="User" style="max-width: 600px;">
                <el-select v-model="form.user_id" filterable>
                <el-option
                    v-for="(item, index) in users"
                    :key="index"
                    :label="item.email"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Public Time" style="max-width: 600px;">
                <el-date-picker @change="changePublicTime" v-model="form.public_time" type="datetime" format="YYYY-MM-DD HH:mm:ss" width="300px"  />
            </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" type="primary" @click="upSocial(0)" :icon="Select">Upload youtube</el-button>
                <el-button size="small" type="primary" @click="upSocial(1)" :icon="Select">Upload Facebook</el-button>
                <el-button size="small" type="primary" @click="upSocial(2)" :icon="Select">Upload Twitter</el-button>
                <el-button size="small" type="primary" @click="upSocial(3)" :icon="Select">Upload Tiktok</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'NewsIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { list as listUsers } from '@/api/user';
import {list as listCategory} from '@/api/news_category';
import {add as uploadSocial} from '@/api/upload_social_article_job'
import { detail, edit } from '@/api/news';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "NewsEdit",
    data(){
        return {
            form: {
                name: '',
                feature_image: '',
                slug: '',
                description: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                user_id: 0,
                news_category_ids: [],
                tags: [],
                public_time: (new Date())
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            users: [],
            categories: [],
            tags: []
        };
    },
    created(){
        this.getDetail();
        this.getUsers();
        this.getCategory();
    },
    methods: {
        async getDetail(){
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                if (res.data.tags != null && res.data.tags != '') {
                    this.form.tags = JSON.parse(res.data.tags);
                    this.tags = this.form.tags;
                }
                this.form.status = this.form.status ? 1 : 0;
                this.form.feature_image = this.form.feature_image != null && this.form.feature_image != '' ? process.env.VUE_APP_BASE_HOST + this.form.feature_image : null;
                if (this.form.public_time != null) {
                    this.form.public_time = new Date(this.form.public_time*1000);
                }
                this.form.news_category_ids = [];
                if (res.data.news_news_categories != null && res.data.news_news_categories.length > 0) {
                    for (let i = 0; i < res.data.news_news_categories.length; i++) {
                        this.form.news_category_ids.push(res.data.news_news_categories[i].news_category_id);
                    }
                }
                
            }
        },
        async getUsers(){
            let res = await listUsers('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.users = res.data.data;
            }
        },
        async getCategory(){
            let res = await listCategory('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input name',
                    type: 'error',
                });
                return;
            }
            if (this.form.user_id <= 0) {
                ElMessage({
                    message: 'Please input user',
                    type: 'error',
                });
                return;
            }
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: 'Please input keywords',
                    type: 'error',
                });
                return;
            }
            if (this.form.description.trim() == '') {
                ElMessage({
                    message: 'Please input description',
                    type: 'error',
                });
                return;
            }
            if (this.form.meta_description.trim() == '') {
                ElMessage({
                    message: 'Please input meta description',
                    type: 'error',
                });
                return;
            }
            if (this.form.news_category_ids.length <= 0) {
                ElMessage({
                    message: 'Please input meta categories',
                    type: 'error',
                });
                return;
            }
            if (this.form.public_time.getTime() < (new Date()).getTime()) {
                ElMessage({
                    message: 'Public time >= now!',
                    type: 'error',
                });
                return;
            }
            
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            formData.append('public_time', this.form.public_time.getTime()/1000);
            // if (this.form.tags.length > 0) {
            //     console.log(this.form.tags);
            //     return;
            // }
            for(var i = 0; i < this.form.tags.length; i++){
                formData.append('tags[]', this.form.tags[i]);
            }
            for(var j = 0; j < this.form.news_category_ids.length; j++) {
                formData.append('news_category_ids[]', Number(this.form.news_category_ids[j]));
            }
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            // if (formData != null) {
            //     console.log(formData);
            //     return;
            // }
            formData.append('status', this.form.status);
            this.$store.state.loading = true;
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.form.image = null;
        },
        changeFile(e){
            this.form.image = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        changePublicTime(e) {
            let t = new Date(e);
            if (t.getTime() < (new Date()).getTime()) {
                ElMessage({
                    message: 'Public time >= now!',
                    type: 'error',
                });
                return;
            }
        },
        async upSocial(social_type){
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('article_id', Number(this.$route.params.id));
            formData.append('public_type', 'public');
            formData.append('social_type', Number(social_type));
            let res = await uploadSocial(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                ElMessage({
                    message: 'upload success',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'upload failure',
                    type: 'error',
                });
            }
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>