<template>
    <el-footer class="footer">Copy Right by Middle Api</el-footer>
</template>
<script>
export default {
    name: "FooterElement",
    data() {
        return {}
    }
};
</script>
<style>

.el-footer {
    padding: 20px 20px !important;
    width: 100%;
}
</style>