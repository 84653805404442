<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-form-item label="Feature Image" style="max-width: 600px">
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" label="Categories" style="max-width: 600px;">
                <el-select
                    v-model="form.product_category_ids"
                    multiple
                    placeholder="Select"
                    style="width: 240px"
                    filterable
                    >
                    <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Provider" style="max-width: 600px;">
                <el-select
                    v-model="form.provider_id"
                    placeholder="Select"
                    style="width: 240px"
                    filterable
                    >
                    <el-option
                        v-for="item in providers"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Features" style="max-width: 600px;">
                <el-select
                    v-model="form.features"
                    multiple
                    placeholder="Features"
                    style="width: 240px"
                    allow-create="true"
                    filterable="true"
                    >
                    <el-option
                        v-for="item in features"
                        :key="item"
                        :label="item"
                        :value="item"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Name" style="max-width: 600px;">
                <el-input v-model="form.name" placeholder="name" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Length" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.length" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Width" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.width" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Height" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.height" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Weight" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.weight" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Barcode" style="max-width: 600px;">
                <el-input v-model="form.barcode" placeholder="barcode" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Sku" style="max-width: 600px;">
                <el-input v-model="form.sku" placeholder="sku" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Slug" style="max-width: 600px;">
                <el-input v-model="form.slug" placeholder="slug" width="300px" readonly />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Import Price" style="max-width: 600px;">
                <el-input-number min="0" step="1" v-model="form.import_price" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Show price" style="max-width: 600px;">
                <el-input-number min="0" step="1" v-model="form.show_price" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Selling price" style="max-width: 600px;">
                <el-input-number min="0" step="1" v-model="form.selling_price" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Keywords" style="max-width: 600px;">
                <el-input v-model="form.keywords" placeholder="Keywords" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Meta Description" style="max-width: 600px;">
                <el-input type="textarea" v-model="form.meta_description" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item label="Description" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-notes"  v-model="form.description" :config="editorConfig"></ckeditor>
            <el-form-item class="el-col" :span="24" label="Status" style="max-width: 600px;">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" label="Ship Type Product" style="max-width: 600px;">
                <el-select v-model="form.type_id" >
                <el-option
                    v-for="(item, index) in productShipTypes"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Is Top" style="max-width: 600px;">
                <el-select v-model="form.is_top" >
                <el-option
                    v-for="(item, index) in topList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Is Public" style="max-width: 600px;">
                <el-select v-model="form.is_public" >
                <el-option
                    v-for="(item, index) in publicList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="User" style="max-width: 600px;">
                <el-select v-model="form.shop_id" disabled filterable>
                <el-option
                    v-for="(item, index) in shops"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-form-item class="el-col" :span="24" label="Images" style="max-width: 600px;">
                <el-upload
                    v-model:file-list="form.images"
                    list-type="picture-card"
                    :before-remove="removeImage"
                    :before-upload="addFile"
                >
                    <el-icon><Plus /></el-icon>
                </el-upload>

                <el-dialog v-model="dialogVisible">
                    <img w-full :src="dialogImageUrl" alt="Preview Image" />
                </el-dialog>
            </el-form-item>
        </el-row>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'ProductIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>
import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { list as listShops } from '@/api/shop';
import {list as listCategory} from '@/api/product_category';
import {list as listProvider} from '@/api/provider';
import { detail, edit, uploadImage, delImage } from '@/api/product';
import { ElMessage, ElMessageBox } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "ProductEdit",
    data(){
        return {
            dialogVisible: false,
            form: {
                name: '',
                feature_image: '',
                slug: '',
                description: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                is_top: 0,
                is_public: 1,
                shop_id: 0,
                product_category_ids: [],
                features: [],
                barcode: '',
                sku: '',
                import_price: 0,
                selling_price: 0,
                show_price: 0,
                provider_id: 0,
                images: [],
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                type_id: 0,
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            topList: [
                {
                    val: 0,
                    label: 'None Top'
                },
                {
                    val: 1,
                    label: 'Top'
                }
            ],
            publicList: [
                {
                    val: 0,
                    label: 'None Public'
                },
                {
                    val: 1,
                    label: 'Public'
                }
            ],
            productShipTypes: [
                {
                    val: 0,
                    label: 'Normal'
                },
                {
                    val: 1,
                    label: 'Frozen'
                },
                {
                    val: 2,
                    label: 'Fragile'
                },
                {
                    val: 3,
                    label: 'Danger'
                },
                {
                    val: 4,
                    label: 'Ex Danger'
                },
                {
                    val: 5,
                    label: 'Other'
                },
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            shops: [],
            categories: [],
            features: [],
            providers: [],
        };
    },
    created(){
        this.getShops();
        this.getCategory();
        this.getDetail();
    },
    methods: {
        async getDetail(){
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                if (res.data.shop.owner_id > 0) {
                    this.getProviders(res.data.shop.owner_id);
                }
                this.form = res.data;
                if (res.data.features != null && res.data.features != '') {
                    this.form.features = JSON.parse(res.data.features);
                    this.features = this.form.features;
                }
                if (res.data.images != null && res.data.images.length > 0) {
                    let images = [];
                    for (let i = 0; i < res.data.images.length; i++) {
                        images.push({
                            id: res.data.images[i].id,
                            name: res.data.images[i].origin_name,
                            url: process.env.VUE_APP_BASE_HOST + res.data.images[i].name
                        });
                    }
                    this.form.images = images;
                }
                this.form.status = this.form.status ? 1 : 0;
                this.form.is_public = this.form.is_public ? 1 : 0;
                this.form.is_top = this.form.is_top ? 1 : 0;
                this.form.feature_image = this.form.feature_image != null && this.form.feature_image != '' ? process.env.VUE_APP_BASE_HOST + this.form.feature_image : null;
                if (this.form.public_time != null) {
                    this.form.public_time = new Date(this.form.public_time*1000);
                }
                this.form.product_category_ids = [];
                if (res.data.prod_product_categories != null && res.data.prod_product_categories.length > 0) {
                    for (let i = 0; i < res.data.prod_product_categories.length; i++) {
                        this.form.product_category_ids.push(res.data.prod_product_categories[i].product_category_id);
                    }
                }
                if (this.form.description == null) {
                    this.form.description = '';
                }
            }
        },
        async getShops(){
            let res = await listShops('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
        async getProviders(owner_id){
            let res = await listProvider('page=1&pagesize=1000&owner_id='+owner_id.toString());
            if (res != null && res.status == 200 && res.data != null) {
                this.providers = res.data.data;
            }
        },
        async getCategory(){
            let res = await listCategory('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input name',
                    type: 'error',
                });
                return;
            }
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: 'Please input keywords',
                    type: 'error',
                });
                return;
            }
            if (this.form.description.trim() == '') {
                ElMessage({
                    message: 'Please input description',
                    type: 'error',
                });
                return;
            }
            if (this.form.meta_description.trim() == '') {
                ElMessage({
                    message: 'Please input meta description',
                    type: 'error',
                });
                return;
            }
            if (this.form.product_category_ids.length <= 0) {
                ElMessage({
                    message: 'Please input meta categories',
                    type: 'error',
                });
                return;
            }
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            formData.append('barcode', this.form.barcode);
            formData.append('sku', this.form.sku);
            formData.append('import_price', this.form.import_price);
            formData.append('show_price', this.form.show_price);
            formData.append('selling_price', this.form.selling_price);
            formData.append('provider_id', this.form.provider_id);
            formData.append('type_id', this.form.type_id);
            formData.append('length', Number(this.form.length));
            formData.append('width', Number(this.form.width));
            formData.append('height', Number(this.form.height));
            formData.append('weight', Number(this.form.weight));
            for(var i = 0; i < this.form.features.length; i++){
                formData.append('features[]', this.form.features[i]);
            }
            for(var j = 0; j < this.form.product_category_ids.length; j++) {
                formData.append('product_category_ids[]', Number(this.form.product_category_ids[j]));
            }
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            formData.append('status', this.form.status);
            formData.append('is_top', this.form.is_top);
            formData.append('is_public', this.form.is_public);
            this.$store.state.loading = true;
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.form.image = null;
        },
        changeFile(e){
            this.form.image = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        async removeImage(uploadFile, uploadFiles){
            if (uploadFile == null || typeof uploadFile.id == 'undefined') {
                return;
            }
            console.log(uploadFiles);
            let ok = await ElMessageBox.confirm(
            'Do you want to delete this item?',
            'Danger',
            {
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              type: 'danger',
              draggable: true
            }
          ).catch(() => {
            return null;
          });
          if (ok != null && ok == "confirm") {
          let res = await delImage(this.$route.params.id, uploadFile.id);
                if (res != null && res.status == 200) {
                    ElMessage({
                        message: 'Save successfully!',
                        type: 'success',
                    });
                    return true;
                } else {
                    ElMessage({
                        message: 'Save failure!',
                        type: 'error',
                    });
                    return false;
                }
            }
            return false;
        },
        handlePictureCardPreview(uploadFile){
            // this.dialogImageUrl.value = uploadFile.url;
            // this.dialogVisible = true;
            console.log(uploadFile);
        },
        async addFile(file) {
            let ok = await ElMessageBox.confirm(
            'Do you want to save this item?',
            'Danger',
            {
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              type: 'info',
              draggable: true
            }
            ).catch(() => {
                return null;
            });
            if (ok != null && ok == 'confirm') {
                let formData = new FormData();
                formData.append('image', file);
                let res = await uploadImage(this.$route.params.id, formData);
                if (res != null && res.status == 200) {
                    ElMessage({
                        message: 'Save successfully!',
                        type: 'success',
                    });
                    this.getDetail();
                    return false;
                } else {
                    ElMessage({
                        message: 'Save failure!',
                        type: 'error',
                    });
                    return false;
                }
            }
            return false;
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>