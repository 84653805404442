<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
        <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-row type="flex" :gutter="1" style="width: 100%;">
                <el-col :span="24" type="flex" style="justify-content: center !important;">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :on-change="addFile"
                        :before-upload="beforeUpload"
                    >

                    <img v-if="form.avatar" :src="form.avatar" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                    </el-upload>
                </el-col>
            </el-row>
                <el-form-item class="el-col" :span="24" label="Email" style="max-width: 600px;">
                    <el-input v-model="form.email" placeholder="example@gmail.com" width="300px" readonly="readonly"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Username" style="max-width: 600px;">
                    <el-input v-model="form.username" placeholder="username1" readonly="readonly" />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Fullname" style="max-width: 600px;">
                    <el-input v-model="form.fullname" placeholder="Nguyen Van A"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Phone Number" style="max-width: 600px;">
                    <el-input v-model="form.phone_number" placeholder="091234567"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Password" style="max-width: 600px;">
                    <el-input v-model="form.password" placeholder="123@123123"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Role" style="max-width: 600px;">
                    <el-tag v-for="(item, index) in form.roles" v-bind:key="index" type="success">{{ item.name }}</el-tag>
                </el-form-item>
            <el-row type="flex" :gutter="1" >
                <el-col :span="24" type="flex" justify="space-between">
                    <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                    <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                    <router-link :to="{name: 'Dashboard'}" style="margin-left: 10px;">
                        <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                    </router-link>
                </el-col>
            </el-row>
        </el-form>
    </el-main>
    </el-container>
</template>
<script setup>

</script>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { ElMessage, ElMessageBox } from 'element-plus';
import { profile, updateProfile, uploadAvatar } from '@/api/admin';
export default {
    name: "ProfileIndex",
    data() {
        return {
            form: {
                username: '',
                fullname: '',
                phone_number: '',
                password: '',
                email: '',
                roles: [],
                // role_name: '',
                avatar: ''
            },
            file: null
        };
    },
    created(){
        this.getProfile();
    },
    methods: {
        async getProfile(){
            this.$store.state.loading = true;
            let res = await profile();
            this.$store.state.loading = false;
            console.log(res);
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                if (this.form.avatar != '') {
                    this.form.avatar = this.form.avatar != null && this.form.avatar != '' ? process.env.VUE_APP_BASE_HOST + this.form.avatar : null;
                }
            }
        },
        async submitForm(){
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('fullname', this.form.fullname);
            formData.append('phone_number', this.form.phone_number);
            console.log();
            let res = await updateProfile(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getProfile();
        },
        async addFile(e){
            ElMessageBox.confirm(
            'Do you want to upload this item?',
            'Info',
            {
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              type: 'success',
              draggable: true
            }
          ).then( async () =>  {
            if (e != null) {
                this.$store.state.loading = true;
                let formData = new FormData();
                formData.append('image', e.raw);
                console.log(e);
                this.$store.state.loading = false;
                let res = await uploadAvatar(formData);
                if (res != null && res.status == 200)  {
                    ElMessage({
                        message: 'Save successfully!',
                        type: 'success',
                    });
                    this.getProfile();
                } else {
                    ElMessage({
                        message: 'Save failure!',
                        type: 'error',
                    });
                }
            }
          }).catch(() => {
            console.log('no');
          });
        },
        beforeUpload(rawFile) {
            console.log(rawFile);
            return false;
        }
    }
};
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>