import { createStore } from "vuex";
import user from "./modules/user";
export default createStore({
    modules: {
        user
      },
    plugins: [],
    state: {
        hideSidebar: false,
        hideHeader: false,
        hideFooter: false,
        isLoading: false,
        hideBreadScumb: false,
        loading: false,
    },
    mutations: {
        setHideSidebar(state, val) {
            state.hideSidebar = val;
        },
        setHideHeader(state, val) {
            state.hideHeader = val;
        },
        setHideFooter(state, val) {
            state.hideFooter = val;
        },
        setIsLoading(state, val) {
            state.isLoading = val;
        },
        setBreadScumb(state, val) {
            state.hideBreadScumb = val;
        },
        setLoading(state, val) {
            state.loading = val;
        }
    },
    actions: {},
    getters: {
        token: state => state.user != null ? state.user.token : null,
        user: state => state.user != null ? state.user.user : null
    }
});