<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-form-item label="Logo" style="max-width: 600px">
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                >

                <img v-if="form.logo" :src="form.logo" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" label="Name" style="max-width: 600px;">
                <el-input v-model="form.name" placeholder="name" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Keywords" style="max-width: 600px;">
                <el-input v-model="form.keywords" placeholder="Keywords" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Phone" style="max-width: 600px;">
                <el-input type="text" v-model="form.phone" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Address" style="max-width: 600px;">
                <el-input type="text" v-model="form.address" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Website" style="max-width: 600px;">
                <el-input type="text" v-model="form.website" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Facebook" style="max-width: 600px;">
                <el-input type="text" v-model="form.facebook" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Tiktok" style="max-width: 600px;">
                <el-input type="text" v-model="form.tiktok" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Zalo" style="max-width: 600px;">
                <el-input type="text" v-model="form.zalo" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Twitter" style="max-width: 600px;">
                <el-input type="text" v-model="form.twitter" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Instagram" style="max-width: 600px;">
                <el-input type="text" v-model="form.instagram" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item label="Description" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-notes"  v-model="form.description" :config="editorConfig"></ckeditor>
            <el-form-item class="el-col" :span="24" label="Status" style="max-width: 600px;">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Owner" style="max-width: 600px;">
                <el-select v-model="form.owner_id" filterable>
                <el-option
                    v-for="(item, index) in users"
                    :key="index"
                    :label="item.email"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'ProviderIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { add } from '@/api/provider';
import { adminshops as listUsers } from '@/api/user';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "ProviderCreate",
    data(){
        return {
            form: {
                name: '',
                logo: '',
                slug: '',
                description: '',
                phone: '',
                website: '',
                image: null,
                status: 0,
                owner_id: 0,
                address: '',
                facebook: '',
                zalo: '',
                twitter: '',
                tiktok: '',
                instagram: ''
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            users: []
        };
    },
    created(){
        this.getUsers();
    },
    methods: {
        async getUsers(){
            let res = await listUsers();
            if (res != null && res.status == 200 && res.data != null) {
                this.users = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input name',
                    type: 'error',
                });
                return;
            }
            if (this.form.phone.trim() == '') {
                ElMessage({
                    message: 'Please input phone',
                    type: 'error',
                });
                return;
            }
            if (this.form.description.trim() == '') {
                ElMessage({
                    message: 'Please input description',
                    type: 'error',
                });
                return;
            }
            if (this.form.image == null) {
                ElMessage({
                    message: 'Please input feature image',
                    type: 'error',
                });
                return;
            }
            if (this.form.owner_id <= 0) {
                ElMessage({
                    message: 'Please input owner',
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            formData.append('website', this.form.website);
            formData.append('phone', this.form.phone);
            formData.append('address', this.form.address);
            formData.append('facebook', this.form.facebook);
            formData.append('tiktok', this.form.tiktok);
            formData.append('zalo', this.form.zalo);
            formData.append('instagram', this.form.instagram);
            formData.append('twitter', this.form.twitter);
            if (this.form.image != null) {
                formData.append('logo', this.form.image);
            }
            formData.append('status', this.form.status);
            formData.append('owner_id', this.form.owner_id);
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.form.image = null;
        },
        changeFile(e){
            this.form.image = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.logo = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>