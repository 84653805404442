<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-form-item class="el-col" :span="24" label="Video" style="max-width: 600px;">
                <el-select v-model="form.video_id" filterable>
                <el-option
                    v-for="(item, index) in videos"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" label="Social Type" style="max-width: 600px;">
                <el-select v-model="form.social_type" >
                <el-option
                    v-for="(item, index) in socialTypes"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" label="Public Type" style="max-width: 600px;">
                <el-select v-model="form.public_type" >
                <el-option
                    v-for="(item, index) in publicList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'SocialVideoIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
} from '@element-plus/icons-vue'
</script>
<script>
import { add } from '@/api/upload_social_video_job';
import { list as listVideos } from '@/api/video';
import { ElMessage } from 'element-plus';
export default {
    name: "SocialVideoCreate",
    data(){
        return {
            form: {
               video_id: 0,
               social_type: 0,
               public_type: 'public'
            },
            statusList: [
                {
                    val: 0,
                    label: 'Created'
                },
                {
                    val: 1,
                    label: 'Processing'
                },
                {
                    val: 2,
                    label: 'Finished'
                },
                {
                    val: 3,
                    label: 'Reprocessing'
                },
            ],
            publicList: [
                {
                    val: 'public',
                    label: 'Public'
                },
                {
                    val: 'private',
                    label: 'Private'
                },
                {
                    val: 'unlisted',
                    label: 'Unlisted'
                },
            ],
            socialTypes: [
                {
                    val: 0,
                    label: 'Youtube'
                },
                {
                    val: 1,
                    label: 'Facebook'
                },
                {
                    val: 2,
                    label: 'Twitter'
                },
                {
                    val: 3,
                    label: 'Tiktok'
                }
            ],
            videos: []
        };
    },
    created(){
        this.getVideos();
    },
    methods: {
        async getVideos(){
            let res = await listVideos('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.videos = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.video_id <= 0) {
                ElMessage({
                    message: 'Please video',
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('video_id', this.form.video_id);
            formData.append('public_type', this.form.public_type);
            formData.append('social_type', this.form.social_type);
            formData.append('status', 0);
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.form.image = null;
        },
        changeFile(e){
            this.form.image = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.logo = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>