<template>
    <search-form 
    :change-keyword="changeKeywords" 
    :change-from-date="changeFromDate" 
    :change-to-date="changeToDate" 
    :on-search="onSearch"
    :on-refresh="onRefresh"
    :on-export-data="onExport"
    />
    <el-table :data="admins" style="padding-left: 20px;">
      <el-table-column label="#" width="50">
        <template #default="scope">
            {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="video.name" label="Name" />
      <el-table-column prop="social_type" label="Social Type" />
      <el-table-column prop="public_type" label="Public Type" />
      <el-table-column label="Create time">
        <template #default="scope">
          {{ (new Date(scope.row.created_at)).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column label="Status">
        <template #default="scope">
          <el-tag v-if="scope.row.status" type="success">{{ $filters.showStatus(scope.row.status) }}</el-tag>
          <el-tag v-else type="danger">{{ $filters.showStatus(scope.row.status) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Update time">
        <template #default="scope">
          {{ (new Date(scope.row.updated_at)).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column class="group-buttons" width="200">
        <template #default="scope">
          <el-button size="small" type="danger" :icon="Delete" @click="openDelete(scope.row.id)">Delete</el-button>
          <router-link :to="{name: 'SocialVideoEdit', params: {id: scope.row.id} }" style="margin-left: 10px;">
            <el-button size="small" class="ms-10" type="success" :icon="Edit">Edit</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination-element 
    :total="search.total" 
    :page-size="search.pagesize" 
    @change="changePage" 
    :prev-click="prevPage" 
    :next-click="nextPage"
    :page-sizes="pagesizes"
    @size-change="handleSizeChange"
    />
  </template>
  <script setup>
  import {
  Edit,
  Delete
} from '@element-plus/icons-vue'
</script>
  <script>
  import { list, del } from '@/api/upload_social_video_job';
  import SearchForm from './components/SearchForm.vue';
  import { ElButton, ElMessage, ElMessageBox } from 'element-plus';
  import PaginationElement from '@/components/PaginationElement.vue';
  export default {
      name: "IndexPage",
      components: {
    SearchForm,
    ElButton,
},
      data(){
        return {
          delete_id: 0,
          admins: [],
          search: {
            keywords: '',
            from_date: 0,
            to_date: 0,
            total: 0,
            page: 1,
            pagesize: 5,
          },
          show: true,
          pagesizes: [10,20,50,100]
        }
      },
      beforeMount(){
        this.$store.state.hideSidebar = false;
        this.$store.state.hideHeader = false;
        this.$store.state.hideBreadScumb = false;
        this.$store.state.hideFooter = false;
      },
      created(){
        var params = this.$router.currentRoute.value.fullPath.split('?');
        var query = '';
        if (params.length > 1) {
          query = params.slice(-1);
        }
        this.getLists(query);
        var currentQuery = this.$router.currentRoute.value.query;
        if (currentQuery.keywords != undefined) {
          this.search.keywords = currentQuery.keywords;
        }
        if (currentQuery.role != undefined) {
          this.search.role = currentQuery.role;
        }
        if (currentQuery.from_date != undefined) {
          this.search.from_date = currentQuery.from_date;
        }
        if (currentQuery.to_date != undefined) {
          this.search.to_date = currentQuery.to_date;
        }
        if (currentQuery.page != undefined) {
          this.search.page = currentQuery.page;
        }
        if (currentQuery.pagesize != undefined) {
          this.search.pagesize = currentQuery.pagesize;
        }
      },
      methods: {
        async getLists(query){
          this.$store.state.loading = true;
          let res = await list(query);
          this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.admins = res.data.data;
                this.search.total = res.data.total;
                this.search.page = res.data.page;
                this.search.pagesize = res.data.pagesize;
            }
        },
        changePage(value){
            if (this.search.page != value) {
                this.search.page = value;
                this.$router.push({ path: '/social-video', query: this.search });
            }
        },
        nextPage(){
            this.search.page = Number(this.search.page)+1;
            this.$router.push({ path: '/social-video', query: this.search });
        },
        prevPage(){
            this.search.page = Number(this.search.page)-1;
            this.$router.push({ path: '/social-video', query: this.search });
        },
        async onSearch(){
          if (this.search.keywords.trim() != '' || this.search.role > 0 || this.search.from_date > 0 || this.search.to_date > 0) {
              this.$router.push({ path: '/social-video', query: this.search });
          }
        },
        async onRefresh(){
          this.search.keywords = '';
          this.search.role = 0;
          this.search.from_date = 0;
          this.search.to_date = 0;
          this.$router.push({ path: '/social-video' });
        },
        async onExport(){

        },
        changeKeywords(e) {
            this.search.keywords = e;
        },
        changeFromDate(e) {
          let from_date = new Date(e);
          if (from_date != null) {
            this.search.from_date = from_date.getTime()/1000;
          }
        },
        changeToDate(e) {
          let to_date = new Date(e);
          if (to_date != null) {
            this.search.to_date = to_date.getTime()/1000;
          }
        },
        openDelete(id){
          ElMessageBox.confirm(
            'Do you want to delete this item?',
            'Danger',
            {
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              type: 'danger',
              draggable: true
            }
          ).then(() => {
            this.agreeDelete(id);
          }).catch(() => {
            console.log('no');
          });
        },
        async agreeDelete(id){
          if (id > 0){
            this.$store.state.loading = true;
              let res = await del(id);
              this.$store.state.loading = false;
              if (res != null && res.status == 200) {
                  for(var i = 0; i < this.admins.length; i++) {
                      if (this.admins[i].id == id) {
                          this.admins.splice(i, 1);
                          break;
                      }
                  }
                  ElMessage({
                      message: 'Delete item successfully.',
                      type: 'success',
                    });
              } else {
                ElMessage({
                    message: 'Delete item failure!.',
                    type: 'error',
                  });
              }
          }
        },
        cancelDelete(id){
          console.log(id);
        },
        handleSizeChange(val){
          this.search.pagesize = Number(val);
          this.$router.push({ path: '/social-video', query: this.search });
        }
      },
      watch: {
        '$route'(to, from){
            console.log(from);
            var params = to.fullPath.split('?');
            var query = '';
            if (params.length > 1) {
                query = params.slice(-1);
            }
            this.getLists(query);
        }
    },
  };
  </script>
  <style>
@media screen and (max-width: 768px) {
  .group-buttons button {
    margin-bottom: 10px;
    margin-left: 0px !important;
  }
}
@media screen and (min-width: 961px) {
  .group-buttons button {
    margin-bottom: 10px;
    margin-left: 10px;
  }
}
</style>