const Role = {
	RoleTypeEditor      : 1,
	RoleTypeAdminManager : 2,
	RoleTypeSupperAdmin  : 3,
  };
const RoleNames = [
	{
		id: Role.RoleTypeEditor,
		name: 'Editor'
	},
	{
		id: Role.RoleTypeAdminManager,
		name: 'Admin'
	},
	{
		id: Role.RoleTypeSupperAdmin,
		name: 'Super Admin'
	},
];
export {Role, RoleNames};