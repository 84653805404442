<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
        <el-form ref="form" :model="form" label-width="auto" label-position="top" style="width:100%;">
            <el-divider content-position="left">Logo</el-divider>
            <el-row type="flex" :gutter="1" style="width: 100%;">
                <el-col :span="24" type="flex" style="justify-content: center !important;">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :on-change="addFileLogo"
                        :before-upload="beforeUploadLogo"
                        :auto-upload="false"
                    >

                    <img v-if="form.logo" :src="form.logo" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                    </el-upload>
                </el-col>
            </el-row>
            <el-divider content-position="left">Icon</el-divider>
            <el-row type="flex" :gutter="1" style="width: 100%;">
                <el-col :span="24" type="flex" style="justify-content: center !important;">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :on-change="addFileIcon"
                        :before-upload="beforeUploadIcon"
                        :auto-upload="false"
                    >

                    <img v-if="form.icon" :src="form.icon" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                    </el-upload>
                </el-col>
            </el-row>
                <el-form-item class="el-col" :span="24" label="Sitename" style="max-width: 600px;">
                    <el-input v-model="form.sitename" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Title" style="max-width: 600px;">
                    <el-input v-model="form.title" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Title En" style="max-width: 600px;">
                    <el-input v-model="form.title_en" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Keywords" style="max-width: 600px;">
                    <el-input v-model="form.keywords" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Meta Description" style="max-width: 600px;">
                    <el-input v-model="form.meta_description" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Meta Description En" style="max-width: 600px;">
                    <el-input v-model="form.meta_description_en" placeholder="" width="300px"  />
                </el-form-item>
            <el-divider content-position="left">About us</el-divider>
                <el-form-item class="el-col" :span="24" label="Name" style="max-width: 600px;">
                    <el-input v-model="form.about_us.name" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Slogan" style="max-width: 600px;">
                    <el-input v-model="form.about_us.slogan" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Description" style="max-width: 600px;">
                    <el-input v-model="form.about_us.description" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Slogan Items" style="max-width: 600px;">
                    <el-select
                        v-model="form.about_us.slogan_items"
                        multiple
                        placeholder="Slogan Items"
                        style="width: 240px"
                        allow-create="true"
                        filterable="true"
                        >
                        <el-option
                            v-for="item in slogan_items"
                            :key="item"
                            :label="item"
                            :value="item"
                        />
                        </el-select>
                </el-form-item>
            <el-row type="flex" :gutter="1" style="width: 100%;">
                <el-col :span="24" type="flex" style="justify-content: center !important;">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :on-change="addFileAboutUs"
                        :before-upload="beforeUploadAboutUs"
                        :auto-upload="false"
                    >

                    <img v-if="form.about_us.image" :src="form.about_us.image" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                    </el-upload>
                </el-col>
            </el-row>
                <el-form-item class="el-col" :span="24" label="Firmvisit" style="max-width: 600px;">
                    <el-input v-model="form.about_us.firmvisit" placeholder="" width="300px"  />
                </el-form-item>
            <el-divider content-position="left">Contact us</el-divider>
                <el-form-item class="el-col" :span="24" label="Email" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.email" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Phone" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.phone" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Phone 2" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.phone_2" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Address" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.address" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Latitude" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.latitude" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Longitude" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.longitude" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Static map" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.embedmap" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Facebook" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.facebook" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Twitter" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.twitter" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Instagram" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.instagram" placeholder="" width="300px"  />
                </el-form-item>
                <el-form-item class="el-col" :span="24" label="Tiktok" style="max-width: 600px;">
                    <el-input v-model="form.contact_us.tiktok" placeholder="" width="300px"  />
                </el-form-item>
            <el-divider content-position="left">Features</el-divider>
            <el-row type="flex" :gutter="1" style="width: 100%;">
                <el-col :span="24">
                   
                        <el-table :data="form.features" style="width: 100%">
                            <el-table-column label="#" width="50">
                                <template #default="scope">
                                    {{ scope.$index }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Name">
                                <template #default="scope">
                                    <el-input v-model="scope.row.name" size="small" />
                                </template>
                            </el-table-column>
                            <el-table-column label="Icon">
                                <template #default="scope">
                                    <el-input v-model="scope.row.icon" size="small" />
                                </template>
                            </el-table-column>
                            <el-table-column label="Image">
                                <template #default="scope">
                                    <el-upload
                                        class="avatar-uploader"
                                        action=""
                                        :show-file-list="false"
                                        :on-change="(e) => addFileFeature(scope.$index, e)"
                                        :before-upload="(e) => beforeUploadFeature(scope.$index, e)"
                                        :auto-upload="false"
                                    >
                                    <img v-if="scope.row.file_preview" :src="scope.row.file_preview" class="avatar" />
                                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                                    </el-upload>
                                </template>
                            </el-table-column>
                            <el-table-column label="Description">
                                <template #default="scope">
                                    <ckeditor size="small" :editor="editor"  v-model="scope.row.description" :config="editorConfig"></ckeditor>
                                </template>
                            </el-table-column>
                            <el-table-column width="100">
                                <template #default="scope">
                                    <el-button size="small" type="danger" :icon="Delete" @click="openDeleteFeature(scope.row)">Delete</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                </el-col>
            </el-row>
                <el-col :span="24" type="flex" justify="space-between">
                    <el-button size="small" type="primary" @click="addFeature" :icon="Plus">Add Features</el-button>
                </el-col>
            <el-divider content-position="left">Testimonials</el-divider>
            <el-row type="flex" :gutter="1" style="width: 100%;">
                <el-col :span="24">
                        <el-table :data="form.testimonials" style="width: 100%">
                            <el-table-column label="#" width="50">
                                <template #default="scope">
                                    {{ scope.$index }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Name">
                                <template #default="scope">
                                    <el-input v-model="scope.row.name" size="small" />
                                </template>
                            </el-table-column>
                            <el-table-column label="Job">
                                <template #default="scope">
                                    <el-input v-model="scope.row.job" size="small" />
                                </template>
                            </el-table-column>
                            <el-table-column label="Image">
                                <template #default="scope">
                                    <el-upload
                                        class="avatar-uploader"
                                        action=""
                                        :show-file-list="false"
                                        :on-change="(e) => addFileTestimonial(scope.$index, e)"
                                        :before-upload="(e) => beforeUploadTestimonial(scope.$index, e)"
                                        :auto-upload="false"
                                    >
                                    <img v-if="scope.row.file_preview" :src="scope.row.file_preview" class="avatar" />
                                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                                    </el-upload>
                                </template>
                            </el-table-column>
                            <el-table-column label="Description">
                                <template #default="scope">
                                    <ckeditor size="small" :editor="editor"  v-model="scope.row.description" :config="editorConfig"></ckeditor>
                                </template>
                            </el-table-column>
                            <el-table-column width="100">
                                <template #default="scope">
                                    <el-button size="small" type="danger" :icon="Delete" @click="openDeleteTestimonial(scope.row)">Delete</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                </el-col>
            </el-row>
            <el-row type="flex" :gutter="1" >
                <el-col :span="24" type="flex" justify="space-between">
                    <el-button size="small" type="primary" @click="addTestimonial" :icon="Plus">Add Testimonial</el-button>
                </el-col>
            </el-row>
            <el-row type="flex" :gutter="1" >
                <el-col :span="24" type="flex" justify="space-between">
                    <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                    <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                    <router-link :to="{name: 'Dashboard'}" style="margin-left: 10px;">
                        <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                    </router-link>
                </el-col>
            </el-row>
        </el-form>
    </el-main>
    </el-container>
</template>
<script setup>
import {
  Select,
  Back,
  Refresh,
  Plus,
  Delete
} from '@element-plus/icons-vue'
</script>
<script>
    import {lastone, edit} from '@/api/app_config'
    import { ElMessage, ElMessageBox } from 'element-plus';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    export default {
        name: "SettingIndex",
        data() {
            return {
                form: {
                    id: '',
                    logo: '',
                    icon: '',
                    sitename: '',
                    title: '',
                    title_en: '',
                    keywords: '',
                    meta_description: '',
                    meta_description_en: '',
                    features: [],
                    about_us: {
                        name: '',
                        slogan: '',
                        description: '',
                        image: '',
                        firmvisit: '',
                        slogan_items: []
                    },
                    contact_us: {
                        phone: '',
                        phone_2: '',
                        address: '',
                        latitude: '',
                        longitude: '',
                        embedmap: '',
                        facebook: '',
                        twitter: '',
                        instagram: '',
                        tiktok: ''
                    },
                    testimonials: []
                },
                logo: null,
                icon: null,
                about_us: null,
                editor: ClassicEditor,
                editorConfig: {
                    // The configuration of the editor.
                },
                slogan_items: []
            }
        },
        created(){
            this.getLastOne();
        },
        methods: {
            async getLastOne(){
                this.$store.state.loading = true;
                let res = await lastone();
                this.$store.state.loading = false;
                if (res != null && res.status == 200 && res.data != null) {
                    this.form = res.data;
                    if (res.data.features != null) {
                        this.form.features = JSON.parse(res.data.features);
                        if (this.form.features.length > 0) {
                            for(let i = 0; i < this.form.features.length; i++) {
                                this.form.features[i].file_preview = this.form.features[i].image != null && this.form.features[i].image != '' ? process.env.VUE_APP_BASE_HOST + this.form.features[i].image : null;
                            }
                        }
                    }
                    if (res.data.about_us != null) {
                        this.form.about_us = JSON.parse(res.data.about_us);
                        this.form.about_us.image = this.form.about_us.image != null && this.form.about_us.image != '' ? process.env.VUE_APP_BASE_HOST + this.form.about_us.image : null;
                        // if (this.form.about_us.slogan_items != null) {
                        //     this.form.about_us.slogan_items = JSON.parse(this.form.about_us.slogan_items);
                        // }
                        this.slogan_items = this.form.about_us.slogan_items;
                    }
                    if (res.data.contact_us != null) {
                        this.form.contact_us = JSON.parse(res.data.contact_us);
                    }
                    if (res.data.testimonials != null) {
                        this.form.testimonials = JSON.parse(res.data.testimonials);
                        if (this.form.testimonials.length > 0) {
                            for(let i = 0; i < this.form.testimonials.length; i++) {
                                this.form.testimonials[i].file_preview = this.form.testimonials[i].image != null && this.form.testimonials[i].image != '' ? process.env.VUE_APP_BASE_HOST + this.form.testimonials[i].image : null;
                            }
                        }
                    }
                    this.form.logo = this.form.logo != null && this.form.logo != '' ? process.env.VUE_APP_BASE_HOST + this.form.logo : null;
                    this.form.icon = this.form.icon != null && this.form.icon != '' ? process.env.VUE_APP_BASE_HOST + this.form.icon : null;
                }
            },
            async submitForm(){
                
                let formData = new FormData();
                if (this.form.sitename.trim() == '') {
                    ElMessage({
                        message: 'Please input sitename',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.title.trim() == '') {
                    ElMessage({
                        message: 'Please input title',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.title_en.trim() == '') {
                    ElMessage({
                        message: 'Please input title en',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.keywords.trim() == '') {
                    ElMessage({
                        message: 'Please input keywords',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.meta_description.trim() == '') {
                    ElMessage({
                        message: 'Please input meta description',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.meta_description_en.trim() == '') {
                    ElMessage({
                        message: 'Please input meta description en',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.about_us.name.trim() == '') {
                    ElMessage({
                        message: 'Please input about us name',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.about_us.slogan.trim() == '') {
                    ElMessage({
                        message: 'Please input about us slogan',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.about_us.description.trim() == '') {
                    ElMessage({
                        message: 'Please input about us description',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.about_us.firmvisit.trim() == '') {
                    ElMessage({
                        message: 'Please input about us firmvisit',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.about_us.slogan_items.length <= 0) {
                    ElMessage({
                        message: 'Please input about us slogan_items',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.contact_us.email.trim() == '') {
                    ElMessage({
                        message: 'Please input contact us email',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.contact_us.phone.trim() == '') {
                    ElMessage({
                        message: 'Please input contact us phone',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.contact_us.phone_2.trim() == '') {
                    ElMessage({
                        message: 'Please input contact us phone 2',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.contact_us.address.trim() == '') {
                    ElMessage({
                        message: 'Please input contact us address',
                        type: 'error',
                    });
                    return;
                }
                if (Number(this.form.contact_us.latitude) == 0) {
                    ElMessage({
                        message: 'Please input contact us latitude',
                        type: 'error',
                    });
                    return;
                }
                if (Number(this.form.contact_us.longitude) == 0) {
                    ElMessage({
                        message: 'Please input contact us longitude',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.contact_us.embedmap.trim() == '') {
                    ElMessage({
                        message: 'Please input contact us static map',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.contact_us.facebook.trim() == '') {
                    ElMessage({
                        message: 'Please input contact us facebook',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.contact_us.twitter.trim() == '') {
                    ElMessage({
                        message: 'Please input contact us twitter',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.contact_us.instagram.trim() == '') {
                    ElMessage({
                        message: 'Please input contact us instagram',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.contact_us.tiktok.trim() == '') {
                    ElMessage({
                        message: 'Please input contact us tiktok',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.features.length <= 0) {
                    ElMessage({
                        message: 'Please input features',
                        type: 'error',
                    });
                    return;
                }
                if (this.form.testimonials.length <= 0) {
                    ElMessage({
                        message: 'Please input testimonials',
                        type: 'error',
                    });
                    return;
                }
                
                formData.append('logo', this.logo);
                formData.append('icon', this.icon);
                formData.append('sitename', this.form.sitename);
                formData.append('title', this.form.title);
                formData.append('title_en', this.form.title_en);
                formData.append('keywords', this.form.keywords);
                formData.append('meta_description', this.form.meta_description);
                formData.append('meta_description_en', this.form.meta_description_en);
                // about us
                formData.append('about_us[name]', this.form.about_us.name);
                formData.append('about_us[slogan]', this.form.about_us.slogan);
                formData.append('about_us[description]', this.form.about_us.description);
                formData.append('about_us[image]', this.about_us);
                formData.append('about_us[firmvisit]', this.about_us.firmvisit);
                // contact us
                formData.append('contact_us[email]', this.form.contact_us.email);
                formData.append('contact_us[phone]', this.form.contact_us.phone);
                formData.append('contact_us[phone_2]', this.form.contact_us.phone_2);
                formData.append('contact_us[address]', this.form.contact_us.address);
                formData.append('contact_us[latitude]', Number(this.form.contact_us.latitude));
                formData.append('contact_us[longitude]', Number(this.form.contact_us.longitude));
                formData.append('contact_us[embedmap]', this.form.contact_us.embedmap);
                formData.append('contact_us[facebook]', this.form.contact_us.facebook);
                formData.append('contact_us[twitter]', this.form.contact_us.twitter);
                formData.append('contact_us[instagram]', this.form.contact_us.instagram);
                formData.append('contact_us[tiktok]', this.form.contact_us.tiktok);
                for(let i = 0; i < this.form.about_us.slogan_items.length; i++) {
                    formData.append('about_us[slogan_items][]', this.form.about_us.slogan_items[i]);
                }
                // features
                for(let i = 0; i < this.form.features.length; i++) {
                    if (this.form.features[i].name.trim() == '') {
                        ElMessage({
                            message: 'Please input feature name',
                            type: 'error',
                        });
                        return;
                    }
                    if (this.form.features[i].icon.trim() == '') {
                        ElMessage({
                            message: 'Please input feature icon',
                            type: 'error',
                        });
                        return;
                    }
                    if (this.form.features[i].description.trim() == '') {
                        ElMessage({
                            message: 'Please input feature description',
                            type: 'error',
                        });
                        return;
                    }
                    if (this.form.features[i].file == null) {
                        ElMessage({
                            message: 'Please input feature image',
                            type: 'error',
                        });
                        return;
                    }
                    formData.append('features['+i.toString()+'][name]', this.form.features[i].name);
                    formData.append('features['+i.toString()+'][icon]', this.form.features[i].icon);
                    formData.append('features['+i.toString()+'][image]', this.form.features[i].file);
                    formData.append('features['+i.toString()+'][description]', this.form.features[i].description);
                }
                // features
                for(let i = 0; i < this.form.testimonials.length; i++) {
                    if (this.form.testimonials[i].name.trim() == '') {
                        ElMessage({
                            message: 'Please input testimonial name',
                            type: 'error',
                        });
                        return;
                    }
                    if (this.form.testimonials[i].job.trim() == '') {
                        ElMessage({
                            message: 'Please input testimonial job',
                            type: 'error',
                        });
                        return;
                    }
                    if (this.form.testimonials[i].description.trim() == '') {
                        ElMessage({
                            message: 'Please input testimonial description',
                            type: 'error',
                        });
                        return;
                    }
                    if (this.form.testimonials[i].file == null) {
                        ElMessage({
                            message: 'Please input testimonial image',
                            type: 'error',
                        });
                        return;
                    }
                    formData.append('testimonials['+i.toString()+'][name]', this.form.testimonials[i].name);
                    formData.append('testimonials['+i.toString()+'][job]', this.form.testimonials[i].job);
                    formData.append('testimonials['+i.toString()+'][image]', this.form.testimonials[i].file);
                    formData.append('testimonials['+i.toString()+'][description]', this.form.testimonials[i].description);
                }
                this.$store.state.loading = true;
                let res = await edit(this.form.id, formData);
                this.$store.state.loading = false;
                if (res != null && res.status == 200) {
                    ElMessage({
                        message: 'Save successfully!',
                        type: 'success',
                    });
                } else {
                    ElMessage({
                        message: 'Save failure!',
                        type: 'error',
                    });
                }
            },
            async freshForm(){
                this.getLastOne();
                this.icon = null;
                this.logo = null;
            },
            addFileIcon(e) {
                this.icon = e.raw;
                var tmppath = URL.createObjectURL(e.raw);
                this.form.icon = tmppath;
            },
            beforeUploadIcon(rawFile){
                console.log(rawFile);
                return true;
            },
            addFileLogo(e) {
                this.logo = e.raw;
                var tmppath = URL.createObjectURL(e.raw);
                this.form.logo = tmppath;
            },
            beforeUploadLogo(rawFile){
                console.log(rawFile);
                return true;
            },
            changeFeatureName(index){
                console.log(index);
            },
            async openDeleteFeature(row){
                let ok = await ElMessageBox.confirm(
                    'Do you want to delete this item?',
                    'Danger',
                    {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'danger',
                    draggable: true
                    }
                ).catch(() => {
                    return null;
                });
                if (ok != null && ok == "confirm") {
                    let founds = this.form.features.filter(e => e.id == row.id);
                    if (founds) {
                        for(var i = 0; i < this.form.features.length; i++) {
                        if (this.form.features[i].id == founds[0].id) {
                            this.form.features.splice(i, 1);
                            break;
                        }
                    }
                    }
                }
            },
            async openDeleteTestimonial(row){
                let ok = await ElMessageBox.confirm(
                    'Do you want to delete this item?',
                    'Danger',
                    {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'danger',
                    draggable: true
                    }
                ).catch(() => {
                    return null;
                });
                if (ok != null && ok == "confirm") {
                    let founds = this.form.testimonials.filter(e => e.id == row.id);
                    if (founds) {
                        for(var i = 0; i < this.form.testimonials.length; i++) {
                        if (this.form.testimonials[i].id == founds[0].id) {
                            this.form.testimonials.splice(i, 1);
                            break;
                        }
                    }
                    }
                }
            },
            addFileFeature(index, e) {
                this.form.features[index].file = e.raw;
                var tmppath = URL.createObjectURL(e.raw);
                this.form.features[index].file_preview = tmppath;
                return false; 
            },
            beforeUploadFeature(index, e) {
                console.log(index, e);
                return;
            },
            addFileTestimonial(index, e) {
                this.form.testimonials[index].file = e.raw;
                var tmppath = URL.createObjectURL(e.raw);
                this.form.testimonials[index].file_preview = tmppath;
                return false; 
            },
            beforeUploadTestimonial(index, e) {
                console.log(index, e);
                return;
            },
            addFileAboutUs(e) {
                this.about_us = e.raw;
                var tmppath = URL.createObjectURL(e.raw);
                this.form.about_us.image = tmppath;
            },
            beforeUploadAboutUs(rawFile){
                console.log(rawFile);
                return true;
            },
            addFeature(){
                this.form.features.push({
                    id: (new Date()).getTime(),
                    name: '',
                    icon: '',
                    description: '',
                    image: '',
                    file: null,
                    file_preview: ''
                });
            },
            addTestimonial(){
                this.form.testimonials.push({
                    id: (new Date()).getTime(),
                    name: '',
                    job: '',
                    description: '',
                    image: '',
                    file: null,
                    file_preview: ''
                });
            }
        }
    };
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>