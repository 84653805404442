<template>
    <el-container style="margin: 30px 20px;">
          <el-main>
            <router-link :to="{name: 'SocialApi'}" style="margin-left: 10px;">
                <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
            </router-link>
          </el-main>
      </el-container>
  </template>
  <script setup>
    import {
    Back,
  } from '@element-plus/icons-vue'
  </script>
    <script>
    import { postToken } from '@/api/socialapi';
    import { ElMessage } from 'element-plus';
    export default {
        name: "TwitterAuth",
        components: {
  },
        data(){
          return {
            admins: [],
          }
        },
        beforeMount(){
          this.$store.state.hideSidebar = false;
          this.$store.state.hideHeader = false;
          this.$store.state.hideBreadScumb = false;
          this.$store.state.hideFooter = false;
        },
        async created(){
          var currentQuery = this.$router.currentRoute.value.query;
          let data = {};
          if (currentQuery.code != undefined) {
            data.code = currentQuery.code;
          }
          if (currentQuery.state != undefined) {
            data.state = currentQuery.state;
          }
          let res = await postToken(data, 'twitter');
          if (res != null && res.status == 200 && res.data != null) {
              ElMessage({
                      message: 'Success!.',
                      type: 'success',
              });
          } else {
              ElMessage({
                      message: 'Error!.',
                      type: 'error',
              });
          }
        },
    };
    </script>
    <style>
  @media screen and (max-width: 768px) {
    .group-buttons button {
      margin-bottom: 10px;
      margin-left: 0px !important;
    }
  }
  @media screen and (min-width: 961px) {
    .group-buttons button {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
  </style>