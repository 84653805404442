import { createApp } from 'vue'
import App from './App.vue'
import store from "./store";
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import CKEditor from '@ckeditor/ckeditor5-vue';
const app = createApp(App)
app.use(store);
app.use(router)
app.use(ElementPlus)
app.use(CKEditor)
app.config.globalProperties.$filters = {
    capitalize(value){
        if (!value) return value;
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
    },
    showStatus(value){
        if (value) return 'active';
        return 'no-active';
    },
    showPublic(value) {
        if (value) return 'public';
        return 'no-public';
    },
    showTop(value) {
        if (value) return 'show';
        return 'not-show';
    },
    showStatusYoutube(value){
        if (value == 3) return 'reprocessing';
        if (value == 1) return 'processing';
        if (value == 2) return 'finished';
        return 'new created';
    },
};
app.mount('#app')
