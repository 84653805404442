<template>
    <el-table :data="admins" style="padding-left: 20px;">
      <el-table-column label="#" width="140">
        <template #default="scope">
            {{ scope.$index }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Name" width="120" />
      <el-table-column label="Status">
        <template #default="scope">
          <el-tag v-if="scope.row.status" type="success">{{ $filters.showStatus(scope.row.status) }}</el-tag>
          <el-tag v-else type="danger">{{ $filters.showStatus(scope.row.status) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column class="group-buttons" width="200">
        <template #default="scope">
          <el-button size="small" type="success" :icon="Edit" @click="GetToken(scope.row.name)">Get Token</el-button>
        </template>
      </el-table-column>
    </el-table>
  </template>
  <script setup>
  import {
  Edit,
} from '@element-plus/icons-vue'
</script>
  <script>
  import { list, getUrl } from '@/api/socialapi';
  import { ElButton, ElMessage } from 'element-plus';
  export default {
      name: "SocialapiPage",
      components: {
    ElButton,
},
      data(){
        return {
          admins: [],
        }
      },
      beforeMount(){
        this.$store.state.hideSidebar = false;
        this.$store.state.hideHeader = false;
        this.$store.state.hideBreadScumb = false;
        this.$store.state.hideFooter = false;
      },
      created(){
        this.getLists();
      },
      methods: {
        async getLists(){
          this.$store.state.loading = true;
          let res = await list();
          this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.admins = res.data.data;
            }
        },
        async GetToken(name){
            this.$store.state.loading = true;
          let res = await getUrl(name);
          this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                console.log(res.data);
                window.location.href = String(res.data.url);
            } else {
                ElMessage({
                    message: 'Get Failure!.',
                    type: 'error',
                });
            }
        },
      },
  };
  </script>
  <style>
@media screen and (max-width: 768px) {
  .group-buttons button {
    margin-bottom: 10px;
    margin-left: 0px !important;
  }
}
@media screen and (min-width: 961px) {
  .group-buttons button {
    margin-bottom: 10px;
    margin-left: 10px;
  }
}
</style>