<template>
    <el-container style="margin: 30px 20px;">
    <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-form-item label="Avatar" style="max-width: 600px">
            </el-form-item>
            <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                >
                <img v-if="form.avatar" :src="form.avatar" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
            </el-row>
            <el-form-item class="el-col" :span="24" label="Email" style="max-width: 600px;">
                <el-input v-model="form.email" placeholder="example@gmail.com" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Fullname" style="max-width: 600px;">
                <el-input v-model="form.name" placeholder="name"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Password" style="max-width: 600px;">
                <el-input v-model="form.password" placeholder=""  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Status" label-width="200px">
                <el-select v-model="form.status">
                <el-option
                    v-for="item in statusList"
                    :key="item.val"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Role" style="max-width: 600px;">
                <el-select v-model="form.role_ids" multiple>
                <el-option
                    v-for="item in roles"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
            </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'AdminIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
    </el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh
} from '@element-plus/icons-vue'
</script>
<script>
import { edit, detail } from '@/api/admin';
import { list as listRole} from '@/api/admin_role';
import { ElMessage, ElMessageBox } from 'element-plus';
import {api_backend} from '@/constants/configs';
export default {
    name: "AdminEdit",
    data(){
        return {
            form: {
                name: '',
                email: '',
                role_ids: 0,
                password: '',
                status: 0
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            roles: []
        };
    },
    created(){
        this.getRoles();
        this.getDetail();
    },
    methods: {
        async getRoles(){
            this.$store.state.loading = true;
            let res = await listRole('page=1&pagesize=1000');
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.roles = res.data.data;
            }
        },
        async getDetail(){
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                this.form.status = Number(res.data.status);
                if (res.data.roles != null) {
                    this.form.role_ids = [];
                    for(let i = 0; i < res.data.roles.length; i++) {
                        this.form.role_ids.push(res.data.roles[i].id);
                    }
                }
                this.form.password = '';
                this.form.avatar = this.form.avatar != null && this.form.avatar != '' ? process.env.VUE_APP_BASE_HOST + this.form.avatar.replace('public', api_backend+'/storage') : null;
            }
        },
        async submitForm(){
            if (this.form.email.trim() == '') {
                ElMessage({
                    message: 'Please input email',
                    type: 'error',
                });
                return;
            }
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input fullname',
                    type: 'error',
                });
                return;
            }
            if (this.form.role_ids.length <= 0) {
                ElMessage({
                    message: 'Please input roles',
                    type: 'error',
                });
                return;
            }
            
            this.$store.state.loading = true;
            let formData = new FormData();
            for (let i = 0; i < this.form.role_ids.length; i++) {
                formData.append('role_ids[]', this.form.role_ids[i]);
            }
            if (this.form.password.trim().length > 0) {
                let ok = await ElMessageBox.confirm(
                    'Do you want to change password this account?',
                    'Danger',
                    {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'danger',
                    draggable: true
                    }
                ).catch(() => {
                    return null;
                });
                if (ok != null && ok == 'confirm') {
                    formData.append('password', this.form.password.trim());
                }
            }
            formData.append('status', this.form.status);
            if (this.form.image != null) {
                formData.append('avatar', this.form.image);
            }
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.form.image = null;
        },
        changeFile(e){
            this.form.image = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.avatar = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>