import request from '@/utils/request'

function add(data) {
  return request({
    url: '/admin/user',
    method: 'post',
    data
  })
}
function detail(id) {
    return request({
        url: '/admin/user/'+id.toString(),
        method: 'get',
      })
}
function edit(id, data) {
    return request({
        url: '/admin/user/'+id.toString(),
        method: 'post',
        data
      })
}
function del(id) {
    return request({
        url: '/admin/user/'+id.toString(),
        method: 'delete',
      })
}
function list(query) {
    return request({
        url: '/admin/user?'+query.toString(),
        method: 'get',
      })
}

function adminshops() {
  return request({
      url: '/admin/user/adminshops',
      method: 'get',
    })
}

export {add, detail, edit, del, list, adminshops};