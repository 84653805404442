<template>
    <el-row class="tac" width="100%">
      <el-col width="100%">
        <img src="/logo.png" width="100%" height="60px"/>
        <el-menu
          active-text-color="#ffd04b"
          background-color="#545c64"
          class="el-menu-vertical-demo"
          :default-active="menuActive"
          text-color="#fff"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item index="1" title="Dashboard">
            <el-link href="/" class="link-item" :class="menuActive == 1 ? 'active' : ''">
                <el-icon><icon-menu /></el-icon>
                <span class="hidden-sm-and-down">Dashboard</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="2" title="Admin" >
            <el-link href="/admin" class="link-item" :class="menuActive == 2 ? 'active' : ''">
                <el-icon><Management /></el-icon>
                <span class="hidden-sm-and-down">Admin</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="3" title="User">
            <el-link href="/user" class="link-item"  :class="menuActive == 3 ? 'active' : ''">
              <el-icon><user-filled /></el-icon>
              <span class="hidden-sm-and-down">User</span>
              </el-link>
          </el-menu-item>
          <el-menu-item index="4" title="Shop">
            <el-link href="/shop" class="link-item"  :class="menuActive == 4 ? 'active' : ''">
              <el-icon><shop /></el-icon>
              <span class="hidden-sm-and-down">Shop</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="5" title="Provider">
            <el-link href="/provider" class="link-item"  :class="menuActive == 5 ? 'active' : ''">
              <el-icon><office-building /></el-icon>
              <span class="hidden-sm-and-down">Provider</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="6" title="Product Category">
            <el-link href="/product-category" class="link-item"  :class="menuActive == 6 ? 'active' : ''">
              <el-icon><copy-document /></el-icon>
              <span class="hidden-sm-and-down">Product Category</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="7" title="Product">
            <el-link href="/product" class="link-item"  :class="menuActive == 7 ? 'active' : ''">
              <el-icon><box /></el-icon>
              <span class="hidden-sm-and-down">Product</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="8" title="Import Stock">
            <el-link href="/import-stock" class="link-item"  :class="menuActive == 8 ? 'active' : ''">
              <el-icon><document-add /></el-icon>
              <span class="hidden-sm-and-down">Import Stock</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="9" title="Export Stock">
            <el-link href="/export-stock" class="link-item"  :class="menuActive == 9 ? 'active' : ''">
              <el-icon><document-remove /></el-icon>
              <span class="hidden-sm-and-down">Export Stock</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="10" title="Order">
            <el-link href="/order" class="link-item"  :class="menuActive == 10 ? 'active' : ''">
              <el-icon><shopping-cart /></el-icon>
              <span class="hidden-sm-and-down">Order</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="11" title="Order">
            <el-link href="/ship-order" class="link-item"  :class="menuActive == 11 ? 'active' : ''">
              <el-icon><van /></el-icon>
              <span class="hidden-sm-and-down">Ship Order</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="12" title="Cost Type">
            <el-link href="/cost-type" class="link-item"  :class="menuActive == 12 ? 'active' : ''">
              <el-icon><money /></el-icon>
              <span class="hidden-sm-and-down">Cost Type</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="13" title="Cost">
            <el-link href="/cost" class="link-item"  :class="menuActive == 13 ? 'active' : ''">
              <el-icon><coin /></el-icon>
              <span class="hidden-sm-and-down">Cost</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="14" title="News Category">
            <el-link href="/news-category" class="link-item"  :class="menuActive == 14 ? 'active' : ''">
              <el-icon><collection /></el-icon>
              <span class="hidden-sm-and-down">News Category</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="15" title="News">
            <el-link href="/news" class="link-item"  :class="menuActive == 15 ? 'active' : ''">
              <el-icon><memo /></el-icon>
              <span class="hidden-sm-and-down">News</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="16" title="Video Category">
            <el-link href="/video-category" class="link-item"  :class="menuActive == 16 ? 'active' : ''">
              <el-icon><collection /></el-icon>
              <span class="hidden-sm-and-down">Video Category</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="17" title="Video">
            <el-link href="/video" class="link-item"  :class="menuActive == 17 ? 'active' : ''">
              <el-icon><video-play /></el-icon>
              <span class="hidden-sm-and-down">Video</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="18" title="Shop Template">
            <el-link href="/shop-template" class="link-item"  :class="menuActive == 18 ? 'active' : ''">
              <el-icon><memo /></el-icon>
              <span class="hidden-sm-and-down">Shop Template</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="19" title="Banner">
            <el-link href="/banner" class="link-item"  :class="menuActive == 19 ? 'active' : ''">
              <el-icon><full-screen /></el-icon>
              <span class="hidden-sm-and-down">Banner</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="20" title="Shop Category">
            <el-link href="/shop-category" class="link-item"  :class="menuActive == 20 ? 'active' : ''">
              <el-icon><collection /></el-icon>
              <span class="hidden-sm-and-down">Shop Category</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="21" title="Shop Category">
            <el-link href="/youtubejob" class="link-item"  :class="menuActive == 21 ? 'active' : ''">
              <el-icon><film /></el-icon>
              <span class="hidden-sm-and-down">Youtube Job</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="22" title="Social Api">
            <el-link href="/socialapi" class="link-item"  :class="menuActive == 22 ? 'active' : ''">
              <el-icon><connection /></el-icon>
              <span class="hidden-sm-and-down">Socail Api</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="23" title="Social Video">
            <el-link href="/social-video" class="link-item"  :class="menuActive == 23 ? 'active' : ''">
              <el-icon><film /></el-icon>
              <span class="hidden-sm-and-down">Socail Video Job</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="24" title="Social Article">
            <el-link href="/social-article" class="link-item"  :class="menuActive == 24 ? 'active' : ''">
              <el-icon><reading /></el-icon>
              <span class="hidden-sm-and-down">Socail Article Job</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="25" title="Make Video">
            <el-link href="/make-video" class="link-item"  :class="menuActive == 25 ? 'active' : ''">
              <el-icon><video-camera-filled /></el-icon>
              <span class="hidden-sm-and-down">Make Video</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="26" title="Make Article">
            <el-link href="/make-article" class="link-item"  :class="menuActive == 26 ? 'active' : ''">
              <el-icon><document-add/></el-icon>
              <span class="hidden-sm-and-down">Make Article</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="27" title="Settings">
            <el-link href="/setting" class="link-item"  :class="menuActive == 27 ? 'active' : ''">
              <el-icon><setting /></el-icon>
              <span class="hidden-sm-and-down">Settings</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="28" title="Profile">
            <el-link href="/profile" class="link-item"  :class="menuActive == 28 ? 'active' : ''">
              <el-icon><user /></el-icon>
              <span class="hidden-sm-and-down">Profile</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="29" title="Logout">
            <el-link href="/logout" class="link-item"  :class="menuActive == 29 ? 'active' : ''">
              <el-icon><lock /></el-icon>
              <span class="hidden-sm-and-down">Logout</span>
            </el-link>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </template>
  
  <script setup>
  import {
    Menu as IconMenu,
    Setting,
    Lock,
    User,
    UserFilled,
    Box,
    ShoppingCart,
    Management,
    Shop,
    OfficeBuilding,
    CopyDocument,
    DocumentAdd,
    DocumentRemove,
    Memo,
    Collection,
    Money,
    Coin,
    FullScreen,
    Van,
    Film,
    Connection,
    Reading,
    VideoCameraFilled,
    VideoPlay
  } from '@element-plus/icons-vue'
  const handleOpen = (key , keyPath) => {
    // console.log(key, keyPath)
  }
  const handleClose = (key, keyPath) => {
    // console.log(key, keyPath)
  }
  
  </script>
  <script>
  export default {
    name: "LeftMenu",
    data(){
      return {
        menuActive: "1",
      }
    },
    created(){
      this.getRoute();
    },
    methods: {
      getRoute() {
        const routeArr = this.$route.path.split("/");
        if (routeArr[1] == 'admin') {
          this.menuActive = "2";
        } else if (routeArr[1] == 'user') {
          this.menuActive = "3";
        } else if (routeArr[1] == 'shop') {
          this.menuActive = "4";
        } else if (routeArr[1] == 'provider') {
          this.menuActive = "5";
        } else if (routeArr[1] == 'product-category') {
          this.menuActive = "6";
        } else if (routeArr[1] == 'product') {
          this.menuActive = "7";
        } else if (routeArr[1] == 'import-stock') {
          this.menuActive = "8";
        } else if (routeArr[1] == 'export-stock') {
          this.menuActive = "9";
        } else if (routeArr[1] == 'order') {
          this.menuActive = "10";
        } else if (routeArr[1] == 'ship-order') {
          this.menuActive = "11";
        } else if (routeArr[1] == 'cost-type') {
          this.menuActive = "12";
        } else if (routeArr[1] == 'cost') {
          this.menuActive = "13";
        } else if (routeArr[1] == 'news-category') {
          this.menuActive = "14";
        } else if (routeArr[1] == 'news') {
          this.menuActive = "15";
        } else if (routeArr[1] == 'video-category') {
          this.menuActive = "16";
        } else if (routeArr[1] == 'video') {
          this.menuActive = "17";
        } else if (routeArr[1] == 'shop-template') {
          this.menuActive = "18";
        } else if (routeArr[1] == 'banner') {
          this.menuActive = "19";
        } else if (routeArr[1] == 'shop-category') {
          this.menuActive = "20";
        } else if (routeArr[1] == 'youtubejob') {
          this.menuActive = "21";
        } else if (routeArr[1] == 'socialapi') {
          this.menuActive = "22";
        } else if (routeArr[1] == 'social-video') {
          this.menuActive = "23";
        } else if (routeArr[1] == 'social-article') {
          this.menuActive = "24";
        } else if (routeArr[1] == 'make-video') {
          this.menuActive = "25";
        } else if (routeArr[1] == 'make-article') {
          this.menuActive = "26";
        } else if (routeArr[1] == 'setting') {
          this.menuActive = "27";
        }  else if (routeArr[1] == 'profile') {
          this.menuActive = "28";
        } else if (routeArr[1] == 'logout') {
          this.menuActive = "29";
        }
      },
    },
    watch:{
      '$route'(to, from) {
        console.log(from);
        const routeArr = to.fullPath.split("/");
        if (routeArr[1] == 'admin') {
          this.menuActive = "2";
        } else if (routeArr[1] == 'user') {
          this.menuActive = "3";
        } else if (routeArr[1] == 'shop') {
          this.menuActive = "4";
        } else if (routeArr[1] == 'provider') {
          this.menuActive = "5";
        } else if (routeArr[1] == 'product-category') {
          this.menuActive = "6";
        } else if (routeArr[1] == 'product') {
          this.menuActive = "7";
        } else if (routeArr[1] == 'import-stock') {
          this.menuActive = "8";
        } else if (routeArr[1] == 'export-stock') {
          this.menuActive = "9";
        } else if (routeArr[1] == 'order') {
          this.menuActive = "10";
        } else if (routeArr[1] == 'ship-order') {
          this.menuActive = "11";
        } else if (routeArr[1] == 'cost-type') {
          this.menuActive = "12";
        } else if (routeArr[1] == 'cost') {
          this.menuActive = "13";
        } else if (routeArr[1] == 'news-category') {
          this.menuActive = "14";
        } else if (routeArr[1] == 'news') {
          this.menuActive = "15";
        } else if (routeArr[1] == 'video-category') {
          this.menuActive = "16";
        } else if (routeArr[1] == 'video') {
          this.menuActive = "17";
        } else if (routeArr[1] == 'shop-template') {
          this.menuActive = "18";
        } else if (routeArr[1] == 'banner') {
          this.menuActive = "19";
        } else if (routeArr[1] == 'shop-category') {
          this.menuActive = "20";
        } else if (routeArr[1] == 'youtubejob') {
          this.menuActive = "21";
        } else if (routeArr[1] == 'socialapi') {
          this.menuActive = "22";
        } else if (routeArr[1] == 'social-video') {
          this.menuActive = "23";
        } else if (routeArr[1] == 'social-article') {
          this.menuActive = "24";
        } else if (routeArr[1] == 'make-video') {
          this.menuActive = "25";
        } else if (routeArr[1] == 'make-article') {
          this.menuActive = "26";
        } else if (routeArr[1] == 'setting') {
          this.menuActive = "27";
        }  else if (routeArr[1] == 'profile') {
          this.menuActive = "28";
        } else if (routeArr[1] == 'logout') {
          this.menuActive = "29";
        }
      }
    }
  };
  </script>
  <style>
  .el-link {
    color: #fff !important;
    justify-content: left !important;
  }
  .el-link.active {
    color: #e6a23c !important;
  }
.link-item {
    background-color: inherit;
    color: inherit;
    width: 100%;
  }
</style>
  