<template>
    <el-row class="row" :gutter="20" style="height: 100%;">
      <el-col :span="22" >
        <div class="header-title">
          <span class="text-large font-600 mr-3">Admin</span>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="toolbar">
          <el-dropdown>
            <el-icon style="margin-right: 8px;" color="white" size="16"><user /></el-icon>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item><el-icon><user /></el-icon>Profile</el-dropdown-item>
                <el-dropdown-item @click="onLogout"><el-icon><lock /></el-icon>Logout</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <span>{{ user.name }}</span>
        </div>
      </el-col>
    </el-row>
  </template>
<script setup>
import {
  User,
  Lock
} from '@element-plus/icons-vue'
</script>
<script>
export default {
    name: "HeaderNav",
    methods: {
      async onLogout(){
        this.$store.dispatch('user/logout');
        this.$router.push({ path: '/login' });
      },
      getRoute() {
        const routeArr = this.$route.path.split("/");
        return routeArr[1];
      },
    },
    data(){
      return {
        user: this.$store.getters.user.user
      }
    },
    watch: {
      '$store.state.user.user': {
        handler(newUser){
            this.user = newUser;
        },
        immediate: true,
        deep: true
      },
    }
}
</script>
<style>
.toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
.header-title {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
.el-header {
  position: relative;
  background-color: #545c64;
  color: white;
}
.el-header .el-row, .el-header .el-col {
  height: 100%;
  padding-bottom: 0px;
}
</style>