import { createRouter, createWebHistory } from "vue-router"
import Dashboard from '../views/Dashboard.vue'
import LoginPage from '../views/LoginPage.vue'
import AdminIndex from '../views/admin/IndexPage.vue'
import AdminAdd from '../views/admin/AddPage.vue'
import AdminEdit from '../views/admin/EditPage.vue'
import UserIndex from '../views/user/IndexPage.vue'
import UserAdd from '../views/user/AddPage.vue'
import UserEdit from '../views/user/EditPage.vue'
import { Role } from "@/constants/role";
import ProductCategoryIndex from '../views/productcategory/IndexPage.vue'
import ProductCategoryEdit from '../views/productcategory/EditPage.vue'
import ProductCategoryAdd from '../views/productcategory/AddPage.vue'
import ProductIndex from '../views/product/IndexPage.vue'
import ProductEdit from '../views/product/EditPage.vue'
import ProductAdd from '../views/product/AddPage.vue'
import OrderIndex from '../views/order/IndexPage.vue'
import OrderAdd from '../views/order/AddPage.vue'
import OrderEdit from '../views/order/EditPage.vue'
import ProfileIndex from '../views/profile/ProfilePage.vue'
import NewsCategoryIndex from '../views/newscategory/IndexPage.vue'
import NewsCategoryEdit from '../views/newscategory/EditPage.vue'
import NewsCategoryAdd from '../views/newscategory/AddPage.vue'
import NewsIndex from '../views/news/IndexPage.vue'
import NewsEdit from '../views/news/EditPage.vue'
import NewsAdd from '../views/news/AddPage.vue'
import ImportStockIndex from '../views/importstock/IndexPage.vue'
import ImportStockEdit from '../views/importstock/EditPage.vue'
import ImportStockAdd from '../views/importstock/AddPage.vue'
import ExportStockIndex from '../views/exportstock/IndexPage.vue'
import ExportStockEdit from '../views/exportstock/EditPage.vue'
import ExportStockAdd from '../views/exportstock/AddPage.vue'
import ShopIndex from '../views/shop/IndexPage.vue'
import ShopEdit from '../views/shop/EditPage.vue'
import ShopAdd from '../views/shop/AddPage.vue'
import ProviderIndex from '../views/provider/IndexPage.vue'
import ProviderEdit from '../views/provider/EditPage.vue'
import ProviderAdd from '../views/provider/AddPage.vue'
import SettingIndex from '../views/settings/SettingPage.vue'
import CostTypeIndex from '../views/costtype/IndexPage.vue'
import CostTypeEdit from '../views/costtype/EditPage.vue'
import CostTypeAdd from '../views/costtype/AddPage.vue'
import CostIndex from '../views/cost/IndexPage.vue'
import CostEdit from '../views/cost/EditPage.vue'
import CostAdd from '../views/cost/AddPage.vue'
import VideoCategoryIndex from '../views/videocategory/IndexPage.vue'
import VideoCategoryEdit from '../views/videocategory/EditPage.vue'
import VideoCategoryAdd from '../views/videocategory/AddPage.vue'
import VideoIndex from '../views/video/IndexPage.vue'
import VideoEdit from '../views/video/EditPage.vue'
import VideoAdd from '../views/video/AddPage.vue'
import ShopTemplateIndex from '../views/shoptemplate/IndexPage.vue'
import ShopTemplateEdit from '../views/shoptemplate/EditPage.vue'
import ShopTemplateAdd from '../views/shoptemplate/AddPage.vue'
import BannerIndex from '../views/banner/IndexPage.vue'
import BannerEdit from '../views/banner/EditPage.vue'
import BannerAdd from '../views/banner/AddPage.vue'
import ShopCategoryIndex from '../views/shopcategory/IndexPage.vue'
import ShopCategoryEdit from '../views/shopcategory/EditPage.vue'
import ShopCategoryAdd from '../views/shopcategory/AddPage.vue'
import YoutubeJobIndex from '../views/youtubejob/IndexPage.vue'
import YoutubeJobEdit from '../views/youtubejob/EditPage.vue'
import YoutubeJobAdd from '../views/youtubejob/AddPage.vue'
import SocialapiPage from '../views/socialapi/Index.vue'
import YoutubeAuth from '../views/socialapi/YoutubeAuth.vue'
import TwitterAuth from '../views/socialapi/TwitterAuth.vue'
import TiktokAuth from '../views/socialapi/TiktokAuth.vue'
import SocialVideoIndex from '../views/socialvideojob/IndexPage.vue'
import SocialVideoEdit from '../views/socialvideojob/EditPage.vue'
import SocialVideoAdd from '../views/socialvideojob/AddPage.vue'
import SocialArticleIndex from '../views/socialarticlejob/IndexPage.vue'
import SocialArticleEdit from '../views/socialarticlejob/EditPage.vue'
import SocialArticleAdd from '../views/socialarticlejob/AddPage.vue'
import MakeVideoIndex from '../views/makevideo/IndexPage.vue'
import MakeVideoEdit from '../views/makevideo/EditPage.vue'
import MakeVideoAdd from '../views/makevideo/AddPage.vue'
import ShipOrderIndex from '../views/shiporder/IndexPage.vue'
import ShipOrderEdit from '../views/shiporder/EditPage.vue'
import ShipOrderAdd from '../views/shiporder/AddPage.vue'
import MakeArticleIndex from '../views/makearticle/IndexPage.vue'
import MakeArticleEdit from '../views/makearticle/EditPage.vue'
import MakeArticleAdd from '../views/makearticle/AddPage.vue'
import store from "@/store";
const routes = [
    {
        path: "/",
        name: "Dashboard",
        redirect: '/dashboard',
        component: Dashboard,
        meta: {title: 'Dashboard', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        children: [{
            path: 'dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: { title: 'dashboard', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        }]
    },
    {
        path: "/login",
        name: "LoginPage",
        component: LoginPage
    },
    {
        path: '/admin',
        // component: AdminIndex,
        redirect: '/admin/list',
        name: 'Admin',
        meta: { title: 'Admin Account', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        children: [
          {
            path: 'list',
            name: 'AdminIndex',
            component: AdminIndex,
            meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
          },
          {
            path: 'add',
            name: 'AdminAdd',
            component: AdminAdd,
            meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          },
          {
            path: 'edit/:id',
            name: 'AdminEdit',
            component: AdminEdit,
            meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            redirect: null,
          }
        ]
    },
    {
        path: '/user',
        // component: UserIndex,
        redirect: '/user/list',
        name: 'User',
        meta: { title: 'User Account', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        children: [
          {
            path: 'list',
            name: 'UserIndex',
            component: UserIndex,
            meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
          },
          {
            path: 'add',
            name: 'UserAdd',
            component: UserAdd,
            meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          },
          {
            path: 'edit/:id',
            name: 'UserEdit',
            component: UserEdit,
            meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          }
        ]
    },
    {
        path: '/product-category',
        // component: EndpointIndex,
        redirect: '/product-category/list',
        name: 'ProductCategory',
        meta: { title: 'Product Category', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        children: [
          {
            path: 'list',
            name: 'ProductCategoryIndex',
            component: ProductCategoryIndex,
            meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
          },
          {
            path: 'add',
            name: 'ProductCategoryAdd',
            component: ProductCategoryAdd,
            meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          },
          {
            path: 'edit/:id',
            name: 'ProductCategoryEdit',
            component: ProductCategoryEdit,
            meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          }
        ]
    },
    {
      path: '/news-category',
      // component: EndpointIndex,
      redirect: '/news-category/list',
      name: 'NewsCategory',
      meta: { title: 'News Category', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'NewsCategoryIndex',
          component: NewsCategoryIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'NewsCategoryAdd',
          component: NewsCategoryAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'NewsCategoryEdit',
          component: NewsCategoryEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
    },
    {
      path: '/video-category',
      // component: EndpointIndex,
      redirect: '/video-category/list',
      name: 'VideoCategory',
      meta: { title: 'Video Category', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'VideoCategoryIndex',
          component: VideoCategoryIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'VideoCategoryAdd',
          component: VideoCategoryAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'VideoCategoryEdit',
          component: VideoCategoryEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
    },
    {
      path: '/news',
      // component: EndpointIndex,
      redirect: '/news/list',
      name: 'News',
      meta: { title: 'News', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'NewsIndex',
          component: NewsIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'NewsAdd',
          component: NewsAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'NewsEdit',
          component: NewsEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
    },
    {
      path: '/video',
      // component: EndpointIndex,
      redirect: '/video/list',
      name: 'Video',
      meta: { title: 'Video', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'VideoIndex',
          component: VideoIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'VideoAdd',
          component: VideoAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'VideoEdit',
          component: VideoEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
    },
    {
      path: '/shop-template',
      // component: EndpointIndex,
      redirect: '/shop-template/list',
      name: 'ShopTemplate',
      meta: { title: 'Shop Template', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'ShopTemplateIndex',
          component: ShopTemplateIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'ShopTemplateAdd',
          component: ShopTemplateAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'ShopTemplateEdit',
          component: ShopTemplateEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
    },
    {
        path: '/product',
        // component: ProductIndex,
        redirect: '/product/list',
        name: 'Product',
        meta: { title: 'Product Manager', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        children: [
          {
            path: 'list',
            name: 'ProductIndex',
            component: ProductIndex,
            meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
          },
          {
            path: 'add',
            name: 'ProductAdd',
            component: ProductAdd,
            meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          },
          {
            path: 'edit/:id',
            name: 'ProductEdit',
            component: ProductEdit,
            meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          }
        ]
    },
    {
        path: '/order',
        //component: OrderIndex,
        redirect: '/order/list',
        name: 'Order',
        meta: { title: 'Order Manager', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        children: [
          {
            path: 'list',
            name: 'OrderIndex',
            component: OrderIndex,
            meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
          },
          {
            path: 'add',
            name: 'OrderAdd',
            component: OrderAdd,
            meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          },
          {
            path: 'edit/:id',
            name: 'OrderEdit',
            component: OrderEdit,
            meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          }
        ]
    },
    {
      path: '/import-stock',
      //component: OrderIndex,
      redirect: '/import-stock/list',
      name: 'ImportStock',
      meta: { title: 'Import Stock', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'ImportStockIndex',
          component: ImportStockIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'ImportStockAdd',
          component: ImportStockAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'ImportStockEdit',
          component: ImportStockEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
  },
  {
    path: '/export-stock',
    //component: OrderIndex,
    redirect: '/export-stock/list',
    name: 'ExportStock',
    meta: { title: 'Export Stock', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    children: [
      {
        path: 'list',
        name: 'ExportStockIndex',
        component: ExportStockIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
      },
      {
        path: 'add',
        name: 'ExportStockAdd',
        component: ExportStockAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'ExportStockEdit',
        component: ExportStockEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      }
    ]
  },
    {
        path: '/shop',
        //component: UserEndpointIndex,
        redirect: '/shop/list',
        name: 'Shop',
        meta: { title: 'Shop', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        children: [
          {
            path: 'list',
            name: 'ShopIndex',
            component: ShopIndex,
            meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
          },
          {
            path: 'add',
            name: 'ShopAdd',
            component: ShopAdd,
            meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          },
          {
            path: 'edit/:id',
            name: 'ShopEdit',
            component: ShopEdit,
            meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
            hidden: true
          }
        ]
    },
    {
      path: '/provider',
      //component: UserEndpointIndex,
      redirect: '/provider/list',
      name: 'Provider',
      meta: { title: 'Provider', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'ProviderIndex',
          component: ProviderIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'ProviderAdd',
          component: ProviderAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'ProviderEdit',
          component: ProviderEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
  },
  {
    path: '/cost-type',
    //component: UserEndpointIndex,
    redirect: '/cost-type/list',
    name: 'CostType',
    meta: { title: 'Cost Type', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    children: [
      {
        path: 'list',
        name: 'CostTypeIndex',
        component: CostTypeIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
      },
      {
        path: 'add',
        name: 'CostTypeAdd',
        component: CostTypeAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'CostTypeEdit',
        component: CostTypeEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      }
    ]
  },
  {
    path: '/cost',
    //component: UserEndpointIndex,
    redirect: '/cost/list',
    name: 'Cost',
    meta: { title: 'Cost', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    children: [
      {
        path: 'list',
        name: 'CostIndex',
        component: CostIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
      },
      {
        path: 'add',
        name: 'CostAdd',
        component: CostAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'CostEdit',
        component: CostEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      }
    ]
  },
  {
    path: '/banner',
    //component: UserEndpointIndex,
    redirect: '/banner/list',
    name: 'Banner',
    meta: { title: 'Banner', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    children: [
      {
        path: 'list',
        name: 'BannerIndex',
        component: BannerIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
      },
      {
        path: 'add',
        name: 'BannerAdd',
        component: BannerAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'BannerEdit',
        component: BannerEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      }
    ]
  },
  {
    path: '/shop-category',
    //component: UserEndpointIndex,
    redirect: '/shop-category/list',
    name: 'ShopCategory',
    meta: { title: 'Shop Category', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    children: [
      {
        path: 'list',
        name: 'ShopCategoryIndex',
        component: ShopCategoryIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
      },
      {
        path: 'add',
        name: 'ShopCategoryAdd',
        component: ShopCategoryAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'ShopCategoryEdit',
        component: ShopCategoryEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      }
    ]
  },
  {
    path: '/youtubejob',
    //component: UserEndpointIndex,
    redirect: '/youtubejob/list',
    name: 'YoutubeJob',
    meta: { title: 'Youtube Job', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    children: [
      {
        path: 'list',
        name: 'YoutubeJobIndex',
        component: YoutubeJobIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
      },
      {
        path: 'add',
        name: 'YoutubeJobAdd',
        component: YoutubeJobAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'YoutubeJobEdit',
        component: YoutubeJobEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
        hidden: true
      }
    ]
  },
    {
        path: "/profile",
        name: "Profile",
        redirect: null,
        component: ProfileIndex,
        meta: {title: 'Profile', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    },
    {
      path: "/setting",
      name: "SettingIndex",
      redirect: null,
      component: SettingIndex,
      meta: {title: 'Setting', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    },
    {
      path: "/socialapi",
      name: "SocialApi",
      redirect: null,
      component: SocialapiPage,
      meta: {title: 'Socail Api', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    },
    {
      path: "/socialapi/youtube/auth",
      name: "YoutubeAuth",
      redirect: null,
      component: YoutubeAuth,
      meta: {title: 'Youtube Auth', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    },
    {
      path: "/socialapi/twitter/auth",
      name: "TwitterAuth",
      redirect: null,
      component: TwitterAuth,
      meta: {title: 'Twitter Auth', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    },
    {
      path: "/socialapi/tiktok/auth",
      name: "TiktokAuth",
      redirect: null,
      component: TiktokAuth,
      meta: {title: 'Tiktok Auth', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
    },
    {
      path: '/social-video',
      // component: EndpointIndex,
      redirect: '/social-video/list',
      name: 'SocialVideo',
      meta: { title: 'Social Video Job', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'SocialVideoIndex',
          component: SocialVideoIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'SocialVideoAdd',
          component: SocialVideoAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'SocialVideoEdit',
          component: SocialVideoEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
    },
    {
      path: '/social-article',
      // component: EndpointIndex,
      redirect: '/social-article/list',
      name: 'SocialArticle',
      meta: { title: 'Social Article Job', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'SocialArticleIndex',
          component: SocialArticleIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'SocialArticleAdd',
          component: SocialArticleAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'SocialArticleEdit',
          component: SocialArticleEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
    },
    {
      path: '/make-video',
      // component: EndpointIndex,
      redirect: '/make-video/list',
      name: 'MakeVideo',
      meta: { title: 'Make Video', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'MakeVideoIndex',
          component: MakeVideoIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'MakeVideoAdd',
          component: MakeVideoAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'MakeVideoEdit',
          component: MakeVideoEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
    },
    {
      path: '/make-article',
      // component: EndpointIndex,
      redirect: '/make-article/list',
      name: 'MakeArticle',
      meta: { title: 'Make Article', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'MakeArticleIndex',
          component: MakeArticleIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'MakeArticleAdd',
          component: MakeArticleAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'MakeArticleEdit',
          component: MakeArticleEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
    },
    {
      path: '/ship-order',
      // component: EndpointIndex,
      redirect: '/ship-order/list',
      name: 'ShipOrder',
      meta: { title: 'Ship Order', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
      children: [
        {
          path: 'list',
          name: 'ShipOrderIndex',
          component: ShipOrderIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]}
        },
        {
          path: 'add',
          name: 'ShipOrderAdd',
          component: ShipOrderAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'ShipOrderEdit',
          component: ShipOrderEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeSupperAdmin, Role.RoleTypeAdminManager]},
          hidden: true
        }
      ]
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
  });
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.token != null && typeof store.getters.token != 'undefined' && store.state.user != 'undefined' && store.state.user.user != 'undefined' && store.state.user.user.email != null) {
        next();
        return;
    }
    next("/login");
  } else {
      next();
  }
});
  export default router; 