<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-form-item class="el-col" :span="24" label="Shop" style="max-width: 600px">
                <el-select v-model="form.shop_id" @change="changeShop" filterable>
                <el-option
                    v-for="(item, index) in shops"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Cost Type" style="max-width: 600px">
                <el-select v-model="form.cost_type_id" filterable>
                <el-option
                    v-for="(item, index) in cost_types"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Name" style="max-width: 600px">
                <el-input v-model="form.name" placeholder="name" />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Amount" style="max-width: 600px">
                <el-input-number min="0" v-model="form.amount" placeholder="0" />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Tax Rate" style="max-width: 600px">
                <el-input-number step="0.1" min="0" v-model="form.tax_rate" placeholder="0" />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Bill Code" style="max-width: 600px">
                <el-input v-model="form.bill_code" placeholder="123456" />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Bill Link" style="max-width: 600px">
                <el-input v-model="form.bill_link" placeholder="http://" />
            </el-form-item>
            <el-form-item label="Bill Image" style="max-width: 600px">
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                >

                <img v-if="form.bill_image" :src="form.bill_image" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item label="Notes" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-notes"  v-model="form.notes" :config="editorConfig"></ckeditor>
            <el-form-item class="el-col" :span="24" label="Spend Date" style="max-width: 600px">
                <el-date-picker v-model="form.spend_date" type="datetime" format="YYYY-MM-DD HH:mm:ss" />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Status" style="max-width: 600px">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'CostIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { add } from '@/api/cost';
import { list as listShops } from '@/api/shop';
import { list as listCostTypes } from '@/api/cost_type';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "CostCreate",
    data(){
        return {
            form: {
                name: '',
                amount: 0,
                tax_rate: 0,
                bill_link: '',
                bill_code: '',
                bill_image: '',
                notes: '',
                image: null,
                status: 0,
                shop_id: 0,
                cost_type_id: 0,
                spend_date: (new Date())
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            shops: [],
            cost_types: []
        };
    },
    created(){
        this.getShops();
    },
    methods: {
        async getShops(){
            let res = await listShops('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
        async getCostTypes(shopId){
            let res = await listCostTypes('page=1&pagesize=1000&shop_id='+shopId.toString());
            if (res != null && res.status == 200 && res.data != null) {
                this.cost_types = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input name',
                    type: 'error',
                });
                return;
            }
            if (this.form.shop_id <= 0) {
                ElMessage({
                    message: 'Please input shop',
                    type: 'error',
                });
                return;
            }
            if (this.form.cost_type_id <= 0) {
                ElMessage({
                    message: 'Please input cost type',
                    type: 'error',
                });
                return;
            }
            
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('amount', Number(this.form.amount));
            formData.append('tax_rate', Number(this.form.tax_rate));
            formData.append('bill_code', this.form.bill_code);
            formData.append('bill_link', this.form.bill_link);
            formData.append('notes', this.form.notes);
            formData.append('status', Number(this.form.status));
            formData.append('shop_id', Number(this.form.shop_id));
            formData.append('cost_type_id', Number(this.form.cost_type_id));
            if (this.form.image != null) {
                formData.append('bill_image', this.form.image);
            }
            formData.append('spend_date', this.form.spend_date.getTime()/1000);
            this.$store.state.loading = true;
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                name: '',
                amount: 0,
                tax_rate: 0,
                bill_link: '',
                bill_code: '',
                bill_image: '',
                notes: '',
                image: null,
                status: 0,
                shop_id: 0,
                cost_type_id: 0,
                spend_date: (new Date())
            };
            this.cost_types = [];
        },
        changeFile(e){
            this.form.image = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.bill_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        changeShop(val) {
            this.getCostTypes(val);
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>