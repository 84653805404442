import request from '@/utils/request'

function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data
  })
}
function getCsrf(){
  return request({
    url: '/admin/csrf',
    method: 'get',
  })
}
function profile(){
  return request({
    url: '/admin/profile',
    method: 'get',
  })
}
function editProfile(data) {
  return request({
    url: '/admin/profile',
    method: 'post',
    data
  })
}
export {getCsrf, login, profile, editProfile};