<template>
    <el-pagination background layout="prev, pager, next, sizes" :total="total" :page-size="pageSize" :page-sizes="pageSizes"/>
</template>
<script>
export default {
    name: "PaninationElement",
    props: {
        total: {
            type: Number,
        },
        pageSize: {
            type: Number
        },
        pageSizes: {
            type: Array
        }
    }
}
</script>
<style>
.el-pagination {
    margin-top: 20px;
}
</style>