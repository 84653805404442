<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-form-item label="Feature Image" style="max-width: 600px">
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                >

                <img v-if="form.image" :src="form.image" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" label="Name" style="max-width: 600px;">
                <el-input v-model="form.name" placeholder="name" />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Url" style="max-width: 600px;">
                <el-input v-model="form.url" placeholder="http://example.com" />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Keywords" style="max-width: 600px;">
                <el-input v-model="form.keywords" placeholder="Keywords" />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Status" style="max-width: 600px;">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Banner Type" style="max-width: 600px;">
                <el-select v-model="form.type_id" >
                <el-option
                    v-for="(item, index) in types"
                    :key="index"
                    :label="item.name + ' '+item.width+'x'+item.height+'px'"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'BannerIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { add, listTypes } from '@/api/banner';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {distance_tolerance} from '@/constants/configs';
export default {
    name: "CostTypeCreate",
    data(){
        return {
            form: {
                name: '',
                url: '',
                image: '',
                keywords: '',
                file: null,
                status: 0,
                type_id: 0
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            types: []
        };
    },
    created(){
        this.getTypes();
    },
    methods: {
        async getTypes(){
            let res = await listTypes('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.types = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input name',
                    type: 'error',
                });
                return;
            }
            if (this.form.url.trim() == '') {
                ElMessage({
                    message: 'Please input url',
                    type: 'error',
                });
                return;
            }
            if (this.form.file == null) {
                ElMessage({
                    message: 'Please input feature image',
                    type: 'error',
                });
                return;
            }
            if (this.form.type_id <= 0) {
                ElMessage({
                    message: 'Please input type',
                    type: 'error',
                });
                return;
            }
            let foundType = this.types.filter(e => e.id == this.form.type_id);
            if (!foundType) {
                ElMessage({
                    message: 'Please input type',
                    type: 'error',
                });
                return;
            }
            let tmppath = URL.createObjectURL(this.form.file);
            const img = new Image();
            img.src = tmppath;
            await img.decode();
            if (Math.abs(img.width - foundType[0].width) > distance_tolerance) {
                ElMessage({
                    message: 'Image width equal '+ foundType[0].width + 'px',
                    type: 'error',
                });
                return;
            }
            if (Math.abs(img.height - foundType[0].height) > distance_tolerance) {
                ElMessage({
                    message: 'Image height equal '+ foundType[0].height + 'px',
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('keywords', this.form.keywords);
            formData.append('url', this.form.url);
            formData.append('status', this.form.status);
            formData.append('type_id', this.form.type_id);
            if (this.form.file != null) {
                formData.append('image', this.form.file);
            }
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                name: '',
                image: '',
                keywords: '',
                file: null,
                status: 0,
                type_id: 0,
                url: ''
            };
        },
        changeFile(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: 'file is wrong format',
                    type: 'error',
                });
                return;
            }
            this.form.file = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>