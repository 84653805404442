<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-form-item class="el-col" :span="24" label="Feature Image" style="max-width:600px;">
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
        <el-form-item label="Video" style="max-width: 600px">
        </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-progress v-if="upload_progress.show" :text-inside="true" :stroke-width="26" :percentage="upload_progress.progress" />
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeVideo"
                    :before-upload="beforeUploadVideo"
                    auto-upload="false"
                    accept="video/mp4,video/x-m4v,video/*"
                >
                <video v-if="form.video" width="320" height="240" controls>
                    <source :src="form.video" type="video/mp4"/>
                    <source :src="form.video" type="video/ogg"/>
                    <source :src="form.video" type="video/x-m4k"/>
                    
                </video>
                    <el-icon v-if="form.video" :size="32" color="#f56c6c"><Edit /></el-icon>
                    <el-icon v-else class="avatar-uploader-icon" color="#67c23a"><Plus /></el-icon>
                </el-upload>
                
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" label="Categories" style="max-width:600px;">
                <el-select
                    v-model="form.video_category_ids"
                    multiple
                    placeholder="Select"
                    style="width: 240px"
                    filterable
                    >
                    <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Tags" style="max-width:600px;">
                <el-select
                    v-model="form.tags"
                    multiple
                    placeholder="Tags"
                    style="width: 240px"
                    allow-create="true"
                    filterable="true"
                    >
                    <el-option
                        v-for="item in tags"
                        :key="item"
                        :label="item"
                        :value="item"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Name" style="max-width:600px;">
                <el-input v-model="form.name" placeholder="name" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Keywords" style="max-width:600px;">
                <el-input v-model="form.keywords" placeholder="Keywords" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Meta Description" style="max-width:600px;">
                <el-input type="textarea" v-model="form.meta_description" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Status" style="max-width:600px;">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="User" style="max-width:600px;">
                <el-select v-model="form.user_id" filterable>
                <el-option
                    v-for="(item, index) in users"
                    :key="index"
                    :label="item.email"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Link Shop" style="max-width:600px;">
                <el-select v-model="form.shop_link_id" filterable>
                <el-option
                    v-for="(item, index) in shopes"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Link Product" style="max-width:600px;">
                <el-select v-model="form.product_link_id" filterable>
                <el-option
                    v-for="(item, index) in products"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
            <el-form-item class="el-col" :span="24" label="Public Time" style="max-width:600px;">
                <el-date-picker @change="changePublicTime" v-model="form.public_time" type="datetime" format="YYYY-MM-DD HH:mm:ss" width="300px"  />
            </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'VideoIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus,
  Edit
} from '@element-plus/icons-vue'
</script>
<script>
import { list as listUsers } from '@/api/user';
import {list as listCategory} from '@/api/video_category';
import {list as listShop} from '@/api/shop';
import {list as listProduct} from '@/api/product';
import { add, upload } from '@/api/video';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {short_video} from '@/constants/configs';
export default {
    name: "VideoCreate",
    data(){
        return {
            form: {
                name: '',
                feature_image: '',
                slug: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                user_id: 0,
                video_category_ids: [],
                tags: [],
                public_time: (new Date()),
                video: '',
                shop_link_id: 0,
                product_link_id: 0
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            users: [],
            categories: [],
            tags: [],
            shopes: [],
            products: [],
            upload_progress: {
                progress: 0,
                result: null,
                show: false
            },
            chunkSize: 1000000,
            chunkNumber: 0,
            currentChunkSize: 0
        };
    },
    created(){
        this.getUsers();
        this.getCategory();
        this.getShops();
        this.getProducts();
    },
    methods: {
        async getUsers(){
            let res = await listUsers('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.users = res.data.data;
            }
        },
        async getCategory(){
            let res = await listCategory('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async getShops(){
            let res = await listShop('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shopes = res.data.data;
            }
        },
        async getProducts(){
            let res = await listProduct('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.products = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input name',
                    type: 'error',
                });
                return;
            }
            if (this.form.user_id <= 0) {
                ElMessage({
                    message: 'Please input user',
                    type: 'error',
                });
                return;
            }
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: 'Please input keywords',
                    type: 'error',
                });
                return;
            }
            if (this.form.image == null) {
                ElMessage({
                    message: 'Please input feature image',
                    type: 'error',
                });
                return;
            }
            if (this.form.video == '') {
                ElMessage({
                    message: 'Please input video',
                    type: 'error',
                });
                return;
            }
            if (this.form.meta_description.trim() == '') {
                ElMessage({
                    message: 'Please input meta description',
                    type: 'error',
                });
                return;
            }
            if (this.form.video_category_ids.length <= 0) {
                ElMessage({
                    message: 'Please input categories',
                    type: 'error',
                });
                return;
            }
            if (this.form.public_time.getTime() < (new Date()).getTime()) {
                ElMessage({
                    message: 'Public time >= now!',
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('user_id', this.form.user_id);
            formData.append('description', this.form.description);
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            formData.append('public_time', this.form.public_time.getTime()/1000);
            for(var i = 0; i < this.form.tags.length; i++){
                formData.append('tags[]', this.form.tags[i]);
            }
            for(var j = 0; j < this.form.video_category_ids.length; j++) {
                formData.append('video_category_ids[]', Number(this.form.video_category_ids[j]));
            }
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            if (this.form.video != '') {
                formData.append('video', this.form.video.replace(process.env.VUE_APP_BASE_HOST, ''));
            }
            if (this.form.product_link_id > 0) {
                formData.append('product_link_id', this.form.product_link_id);
            }
            if (this.form.shop_link_id > 0) {
                formData.append('shop_link_id', this.form.shop_link_id);
            }
            formData.append('status', this.form.status);
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
                this.$router.push({ path: '/video' });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                name: '',
                feature_image: '',
                slug: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                user_id: 0,
                video_category_ids: [],
                tags: [],
                public_time: (new Date()),
                video: '',
                shop_link_id: 0,
                product_link_id: 0
            };
            this.upload_progress = {
                progress: 0,
                result: null,
                show: false
            };
            this.chunkSize = 1000000;
            this.chunkNumber = 0;
            this.currentChunkSize = 0;
        },
        changeFile(e){
            this.form.image = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        changePublicTime(e) {
            let t = new Date(e);
            if (t.getTime() < (new Date()).getTime()) {
                ElMessage({
                    message: 'Public time >= now!',
                    type: 'error',
                });
                return;
            }
        },
        async changeVideo(e){
            if(e.raw == null || e.raw.type.indexOf('video/') !== 0) {
                ElMessage({
                    message: 'wrong format',
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < short_video.size;
            if (!isLt2M) {
                ElMessage({
                    message: 'file less or equal '+ short_video.size + 'MB',
                    type: 'error',
                });
                return;
            }
            let file = e.raw;
            this.upload_progress.progress = 0;
            this.upload_progress.result = null;
            const blockCount = Math.ceil(file.size / this.chunkSize);
            this.chunkNumber = 0;
            const identifier = `${file.size}-${file.name.replace('.', '')}`; 
            this.upload_progress.show = true;
            this.$store.state.loading = true;
            while (this.chunkNumber < blockCount) {
                const start = this.chunkNumber * this.chunkSize;
                const end = Math.min(file.size, start + this.chunkSize);
                this.currentChunkSize = this.chunkSize;
                if (this.chunkNumber + 1 === blockCount) {
                    this.currentChunkSize = file.size - start;
                }
                const params = new FormData();
                params.append('resumableChunkNumber', this.chunkNumber);
                params.append('resumableChunkSize', this.currentChunkSize);
                params.append('resumableCurrentChunkSize', this.currentChunkSize);
                params.append('resumableTotalSize', file.size);
                params.append('resumableType', file.type);
                params.append('resumableIdentifier', identifier);
                params.append('resumableFilename', file.name);
                params.append('resumableRelativePath', file.name);
                params.append('resumableTotalChunks', blockCount);
                params.append('file', file.slice(start, end), file.name);

                let res = await upload(params);
                if (res != null && res.status == 200 && res.data != null) {
                    this.chunkNumber++;
                    if (res.data.done != null) {
                        this.upload_progress.progress = res.data.done;
                    }
                    if (res.data.url_base != null) {
                        this.form.video = process.env.VUE_APP_BASE_HOST + res.data.url_base;
                        this.upload_progress.progress = 100;
                        this.upload_progress.show = false;
                        ElMessage({
                            message: 'upload success',
                            type: 'success',
                        });
                        this.chunkNumber = 0;
                        this.$store.state.loading = false;
                        return;
                    }
                } else {
                    ElMessage({
                        message: 'upload failure',
                        type: 'error',
                    });
                    this.chunkNumber = 0;
                    this.$store.state.loading = false;
                    return;
                }
            }
            this.$store.state.loading = false;
        },
        beforeUploadVideo(rawFile){
            console.log(rawFile);
            return false;
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>