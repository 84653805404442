<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-form-item label="Feature Image" style="max-width: 600px">
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
        <el-form-item label="Icon" style="max-width: 600px">
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeIcon"
                    :before-upload="beforeIcon"
                >

                <img v-if="form.icon" :src="form.icon" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" label="Name" style="max-width:600px;">
                <el-input v-model="form.name" placeholder="name" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Slug" style="max-width:600px;">
                <el-input v-model="form.slug" placeholder="slug" width="300px" readonly />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Keywords" style="max-width:600px;">
                <el-input v-model="form.keywords" placeholder="Keywords" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Meta Description" style="max-width:600px;">
                <el-input type="textarea" v-model="form.meta_description" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item label="Description" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-notes"  v-model="form.description" :config="editorConfig"></ckeditor>
            <el-form-item class="el-col" :span="24" label="Status" style="max-width:600px;">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'ShopCategoryIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { edit, detail } from '@/api/shop_category';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "NewsCategoryEdit",
    data(){
        return {
            form: {
                name: '',
                feature_image: '',
                slug: '',
                description: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                icon: '',
                icon_file: null
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            }
        };
    },
    created(){
        this.getDetail();
    },
    methods: {
        async getDetail(){
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                this.form.status = this.form.status ? 1 : 0;
                this.form.feature_image = this.form.feature_image != null && this.form.feature_image != '' ? process.env.VUE_APP_BASE_HOST + this.form.feature_image : null;
                this.form.icon = this.form.icon != null && this.form.icon != '' ? process.env.VUE_APP_BASE_HOST + this.form.icon : null;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: 'Please input name',
                    type: 'error',
                });
                return;
            }
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: 'Please input keywords',
                    type: 'error',
                });
                return;
            }
            if (this.form.description.trim() == '') {
                ElMessage({
                    message: 'Please input description',
                    type: 'error',
                });
                return;
            }
            if (this.form.meta_description.trim() == '') {
                ElMessage({
                    message: 'Please input meta description',
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            if (this.form.icon_file != null) {
                formData.append('icon', this.form.icon_file);
            }
            formData.append('status', this.form.status);
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.form.image = null;
        },
        changeFile(e){
            this.form.image = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        changeIcon(e){
            this.form.icon_file = e.raw;
            var tmppath = URL.createObjectURL(e.raw);
            this.form.icon = tmppath;
        },
        beforeIcon(rawFile){
            console.log(rawFile);
            return false;
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>