import request from '@/utils/request'

function add(data) {
  return request({
    url: '/admin/admin',
    method: 'post',
    data
  })
}
function detail(id) {
    return request({
        url: '/admin/admin/'+id.toString(),
        method: 'get',
      })
}
function edit(id, data) {
    return request({
        url: '/admin/admin/'+id.toString(),
        method: 'post',
        data
      })
}
function del(id) {
    return request({
        url: '/admin/admin/'+id.toString(),
        method: 'delete',
      })
}
function list(query) {
    return request({
        url: '/admin/admin?'+query.toString(),
        method: 'get',
      })
}
function logout() {
    return request({
        url: '/admin/logout',
        method: 'get'
    });
}
function profile(){
    return request({
        url: '/admin/profile',
        method: 'get'
    });
}
function updateProfile(data){
  return request({
      url: '/admin/profile/update',
      method: 'post',
      data
  });
}
function uploadAvatar(data){
  return request({
      url: '/admin/profile/avatar',
      method: 'post',
      data
  });
}
export {add, detail, edit, del, list, logout, profile, updateProfile, uploadAvatar};