<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-form-item class="el-col" :span="24" label="Shop" style="max-width: 600px;">
                <el-select v-model="form.shop_id" @change="changeShop" filterable>
                <el-option
                    v-for="item in shops"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Notes" style="max-width: 600px;">
                <el-input v-model="form.notes" placeholder="notes" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" label="Import Date" style="max-width: 600px;">
                <el-date-picker v-model="form.import_date" type="datetime" format="YYYY-MM-DD HH:mm:ss" width="300px"  />
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24">
                <el-table :data="form.products" style="width: 100%">
                    <el-table-column label="#" width="50">
                        <template #default="scope">
                            {{ scope.$index }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Product">
                        <template #default="scope">
                            <el-select v-model="scope.row.id" filterable>
                                <el-option
                                    v-for="item in products"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="Quantity">
                        <template #default="scope">
                            <el-input-number v-model="scope.row.qty" size="small" step="1" min="1" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Notes">
                        <template #default="scope">
                            <el-input v-model="scope.row.notes" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Operaters" width="100">
                        <template #default="scope">
                            <el-button size="small" type="danger" @click="removeProduct(scope.$index)" :icon="Delete">Delete</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="addProduct" :icon="Plus">Add Product</el-button>
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" label="Status" style="max-width: 600px;">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item.label"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">Save</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">Refresh</el-button>
                <router-link :to="{name: 'ImportStockIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">Back</el-button>
                </router-link>
            </el-col>
        </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus,
  Delete
} from '@element-plus/icons-vue'
</script>
<script>
import { add } from '@/api/import_stock';
import {list as listShops} from '@/api/shop';
import {list as listProducts} from '@/api/product';
import { ElMessage } from 'element-plus';
export default {
    name: "ImportStockAdd",
    data(){
        return {
            form: {
                shop_id: 0,
                products: [],
                notes: '',
                import_date: (new Date()),
                status: 1
            },
            statusList: [
                {
                    val: 0,
                    label: 'No Active'
                },
                {
                    val: 1,
                    label: 'Active'
                }
            ],
            shops: [],
            products: []
        };
    },
    created(){
        this.getShops();
    },
    methods: {
        async getShops() {
            let res = await listShops('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
        async getProducts(shopId) {
            let res = await listProducts('page=1&pagesize=10000&shop_id='+shopId.toString());
            if (res != null && res.status == 200 && res.data != null) {
                this.products = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.shop_id == 0) {
                ElMessage({
                    message: 'Please input shop',
                    type: 'error',
                });
                return;
            }
            if (this.form.products.length <= 0) {
                ElMessage({
                    message: 'Please input products',
                    type: 'error',
                });
                return;
            }
            let formData = new FormData();
            formData.append('shop_id', this.form.shop_id);
            formData.append('notes', this.form.notes);
            formData.append('import_date', this.form.import_date.getTime()/1000);
            formData.append('status', this.form.status);
            if (this.form.products.length > 0) {
                for(let i = 0; i < this.form.products.length; i++) {
                    formData.append('products['+i+'][id]', Number(this.form.products[i].id));
                    formData.append('products['+i+'][qty]', Number(this.form.products[i].qty)); 
                    formData.append('products['+i+'][notes]', this.form.products[i].notes); 
                }
            }
            this.$store.state.loading = true;
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: 'Save successfully!',
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: 'Save failure!',
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                shop_id: 0,
                products: [],
                notes: ''
            };
            this.products = [];
        },
        removeProduct(index) {
            this.form.products.splice(index, 1);
        },
        addProduct(){
            this.form.products.push({
                id: 0,
                qty: 1,
                notes: ''
            });
        },
        changeShop(value){
            this.form.products = [];
            this.getProducts(value);
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>